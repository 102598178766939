import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";

export default class ErrorBlock extends React.Component {
  render() {
    const { code, title, message } = this.props;

    return (
      <div>
        <h1>
          <CancelIcon className={"largeIcon failureColor"} />
        </h1>
        <h1>{title}</h1>
        <br />
        <p style={{ textAlign: "center" }}>
          {message} Please try to update your profile again in the Careem mobile
          app or on <a href="https://app.careem.com/profile">app.careem.com</a>.
          If the problem persists, please contact support.
        </p>
        <br />
        <h5>Support Information: {code}</h5>
      </div>
    );
  }
}
