import React from "react";
import Lottie from "react-lottie-player";

import lottieJson from "./spinner.json";

class LoadingSpinner extends React.Component {
  render() {
    return (
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: "114px", margin: "auto" }}
      />
    );
  }
}

export default LoadingSpinner;
