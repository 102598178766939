import { init as initApm } from "@elastic/apm-rum";

export const ARGUS_APM = initApm({
  environment: process.env.NODE_ENV,
  serviceName: "identity-react-frontend",
  serverUrl: process.env.REACT_APP_ARGUS_APM_SERVER,
  pageLoadTransactionName: "home",
  disableInstrumentations: ["click", "xmlhttprequest", "fetch"],
  flushInterval: 1000,
});
