/**
 * Detects if the session storage is accessible
 *
 * @returns boolean
 */
export function isSessionStorage() {
  try {
    return typeof window.sessionStorage !== undefined;
  } catch (e) {
    return false;
  }
}

/**
 * Parse acr_values into a plain JS object
 *
 * @returns {params}
 */
export const parseAcrValues = (acrValues = "") => {
  if (!acrValues || typeof acrValues !== "string") return {};
  const params = {};
  const pairs = acrValues.split(" ");
  for (let p = 0; p < pairs.length; p++) {
    const pair = pairs[p].split(":");
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return params;
};
