export const errorMap = new Map([
  ["APP-0001", "INTERNAL_SERVER_ERROR"],
  ["APP-0020", "ERROR_RATE_LIMIT_REACHED"],
  ["PN-0001", "INVALID_PHONE_NUMBER"],
  ["RC-0001", "VERIFYING_OTP_ERROR"],
  ["US-0013", "USER_TEMPORARILY_BLOCKED"],
  ["US-0016", "PHONE_ALREADY_USED"],
  ["US-0051", "BAD_CREDENTIALS"],
  ["US-0067", "INVALID_PASSWORD"],
  ["US-0052", "INVALID_PASSWORD"],
  ["US-0068", "USER_BLOCKED"],
  ["US-0089", "USER_BLOCKED_FOR_SIGNUP"],
  ["US-0111", "INVALID_CHALLENGE_CARD"],
  ["US-0112", "INVALID_CHALLENGE_PASSWORD"],
  ["US-0113", "INVALID_CHALLENGE_NAME"],
  ["US-0114", "INVALID_CHALLENGE_EMAIL"],
  ["US-0115", "INVALID_CHALLENGE_PIN"],
  ["US-0150", "MAX_LIMIT_EXCEEDED"],
  // Legacy
  ["APP-0088", "INTERNAL_SERVER_ERROR"],
  ["ERROR", "INTERNAL_SERVER_ERROR"],
  ["PN-0011", "PHONE_ALREADY_USED"],
  ["US-0002", "USER_NOT_FOUND"],
  ["US-0012", "BAD_CREDENTIALS"],
  ["US-0036", "EMAIL_ALREADY_USED"],
  ["US-0025", "INVALID_PROMOTION_CODE"],
  ["US-0058", "INVALID_PROMOTION_CODE"],
  ["US-0085", "USER_NOT_FOUND_FOR_SIGNUP_TOKEN"],
]);

export function getErrorIdFromErrorCode(errorCode) {
  return errorMap.get(errorCode);
}
