import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";

import Container from "./components/Container";
import ChallengeLoader from "./components/passwordrecovery/ChallengeLoader";
import NewPassword from "./components/passwordrecovery/NewPassword";
import SessionUnknown from "./components/SessionUnknown";
import PasswordChanged from "./components/passwordrecovery/PasswordChanged";
import ConfirmProfileUpdate from "./components/profile/ConfirmProfileUpdate";
import CorporateUserEnrolment from "./components/corporate/CorporateUserEnrolment";
import CorporateEmailVerification from "./components/corporate/CorporateEmailVerification";
import ClientManagement from "./components/clientmanagement/ClientManagement";
import AlertConfirm from "./components/alerts/Alert";
import Login from "./components/login/Login";
import OneClickView from "./components/one-click/view/OneClickView";
import SignupOrchestrator from "./components/signup/SignupOrchestrator";
import EmailVerification from "./components/emailverification/EmailVerification";
import SignIn from "./components/signin/SignIn";
import MotInvitation from "./components/invitation/MotInvitation";

// register SPA routers
const Root = () => {
  return (
    <Router>
      <Switch>
        <ApmRoute
          exact
          path="/email/:verificationId/verify"
          render={(props) => <EmailVerification {...props} verify={true} />}
        />
        <ApmRoute
          exact
          path="/email/:verificationId/unlink"
          render={(props) => <EmailVerification {...props} unlink={true} />}
        />
        <ApmRoute
          exact
          path="/email/:verificationId"
          render={(props) => <EmailVerification {...props} />}
        />
        <Container>
          <Switch>
            <ApmRoute exact path="/" component={ChallengeLoader} />
            <ApmRoute path="/invitation/:token" component={MotInvitation} />
            <ApmRoute path="/session_init" component={ChallengeLoader} />
            <ApmRoute path="/login/one-click" component={OneClickView} />
            <ApmRoute path="/login" component={Login} />
            <ApmRoute path="/password/:token" component={NewPassword} />
            <ApmRoute path="/password_success" component={PasswordChanged} />
            <ApmRoute path="/session_unknown" component={SessionUnknown} />
            <ApmRoute
              path="/profile/update/confirm/:token"
              component={ConfirmProfileUpdate}
            />
            <ApmRoute
              exact
              path="/corporate/invitation/:token"
              component={CorporateUserEnrolment}
            />
            <ApmRoute
              exact
              path="/corporate/verify/:token"
              component={CorporateEmailVerification}
            />
            <ApmRoute
              exact
              path="/client-management/"
              component={ClientManagement}
            />
            <ApmRoute exact path="/signup/" component={SignupOrchestrator} />
            <ApmRoute exact path="/alerts/:alert" component={AlertConfirm} />
            <ApmRoute exact path="/signin/:id" component={SignIn} />
            <ApmRoute path="*" component={SessionUnknown} />
          </Switch>
        </Container>
      </Switch>
    </Router>
  );
};

export default Root;
