import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withTranslation, Trans } from "react-i18next";
import { useTimer } from "../../../otp/Timer";
import { DEFAULT_RETRY_TIMEOUT } from "../constants";
import ChangePhone from "./ChangePhone";
import styles from "../styles";
import PromoLine from "../BannerPromoLine";

function DesktopView(props) {
  const {
    classes,
    phoneNumber,
    merchant,
    onRetry,
    onChangePhone,
    t,
    i18n,
    promoValue,
    promoType,
    promoOutcome
  } = props;
  const [retrySeconds, setRetrySeconds] = useTimer(DEFAULT_RETRY_TIMEOUT);

  const clickAndResetTimer = (event) => {
    onRetry(event);
    setRetrySeconds(DEFAULT_RETRY_TIMEOUT);
  };
  return (
    <Fragment>
      <div dir={i18n.dir()} className={classes.title}>
        <Typography component="h1" className={classes.formTitle}>
          {t("one_click_checkout:we_sent_notification")}
        </Typography>
        <ChangePhone phoneNumber={phoneNumber} onChangePhone={onChangePhone} />
        {promoValue && <PromoLine 
          promoValue={promoValue}
          promoType={promoType}
          promoOutcome={promoOutcome}/> }
        <Typography component="h3" className={classes.formSubtitle}>
          <Trans
            t={t}
            i18nKey="one_click_checkout:open_app_to_continue_checkout"
          >
            Open Careem app to continue checkout on
            <span>{{ merchantName: merchant }}</span>.
          </Trans>
        </Typography>
      </div>
      <div className={classes.sendAgainContainer}>
        <Button
          id="btn-send-again"
          name="send-again"
          type="button"
          onClick={clickAndResetTimer}
          disabled={retrySeconds > 0}
          classes={{
            root: classes.sendAgainButton,
            disabled: classes.sendAgainButtonDisabled,
            text: classes.sendAgainButtonText,
          }}
          size="large"
          {...(retrySeconds > 0 && {
            startIcon: (
              <span className={classes.sendAgainButtonIcon}>
                {retrySeconds}
              </span>
            ),
          })}
        >
          {t("one_click_checkout:Resend Push/SMS")}
        </Button>
      </div>
    </Fragment>
  );
}

DesktopView.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.func,
  classes: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  merchant: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  promoValue: PropTypes.string,
  promoType: PropTypes.string,
  promoOutcome: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(DesktopView));
