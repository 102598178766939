import React from "react";
import Types from "prop-types";
import LanguageSelector from "../LanguageSelector";
import { ReCaptcha } from "../Guard";
import { Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import PhoneNumber from "../common/PhoneNumber";
import AppleButton from "../common/AppleButton";
import GoogleButton from "../common/GoogleButton";
import Analytika from "../../analytika";
import eventAnalytics from "../../analytikaV2";
import HelpCenterButton from "../common/HelpCenterButton";
import { ANALYTIKA_EVENTS, HELP_CENTER_LINK } from "../../utils/constants";

// signup flow types:
export const SIGNUP_WITH_PHONE = "phone";
export const SIGNUP_CORPORATE = "corporate";
export const SIGNUP_WITH_APPLE = "apple";
export const SIGNUP_WITH_GOOGLE = "google";

export class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.onPasswordChange = props.onPasswordChange;
    this.onChange = props.onChange;
    this.onPhoneChange = props.onPhoneChange;
    this.onSubmit = props.onSubmit;
    this.phoneNumber = props.phoneNumber;
    this.state = {
      nameProvided: false,
      isValidPhoneNumber: false,
    };
    this.nameField = React.createRef();
    this.onIsValidPhoneNumberChange = this.onIsValidPhoneNumberChange.bind(
      this
    );
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.SIGNUP_FORM);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.SIGNUP_FORM,
      }
    );
  }
  onIsValidPhoneNumberChange(isValid) {
    this.setState({ isValidPhoneNumber: isValid });
  }
  onAppleIdChange(props) {
    // let { proofToken, appleIdToken, fullname, email, error } = props;
    let { appleIdToken, fullname } = props;
    this.props.onAppleIdChange(props);

    if (appleIdToken) {
      this.setState({
        nameProvided: fullname?.length > 0,
      });
    }
  }
  onTermsClickHandler() {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_version,
      {
        option_name: "terms_and_conditions",
      }
    );
    window.open("https://www.careem.com/terms", "_blank");
  }
  onGoogleIdChange(props) {
    // let { proofToken, googleIdToken, error } = props;
    this.props.onGoogleIdChange(props);
  }

  formHeader() {
    const { t, flowType } = this.props;
    switch (flowType) {
      case SIGNUP_CORPORATE:
      case SIGNUP_WITH_PHONE:
        return t("signup:title");
      case SIGNUP_WITH_APPLE:
        return t("signup:continue_with_apple");
      case SIGNUP_WITH_GOOGLE:
        return t("signup:continue_with_google");
      default:
        break;
    }
  }

  renderClassic() {
    const {
      t,
      error,
      submitting,
      visibleFields,
      fullname,
      email,
      password,
    } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <Helmet>
          <title>Signup | Careem</title>
        </Helmet>

        <div className="help-lang-container">
          <HelpCenterButton href={HELP_CENTER_LINK.CREATE_ACCOUNT} />
          <LanguageSelector />
        </div>
        <h1> {this.formHeader()} </h1>

        {error && <div className="invalid show">{error}</div>}

        {visibleFields.fullname && (
          <div className="material-form-field">
            <input
              type="text"
              autoFocus={visibleFields.fullname}
              autoComplete="off"
              id="fullname"
              name="fullname"
              required
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_SignupForm_Fullname_Focused", {
                  phonenumber: this.phoneNumber,
                })
              }
              onChange={this.onChange}
              value={fullname}
            />
            <label className="material-form-field-label" htmlFor="fullname">
              {t("signup:full_name")}
            </label>
          </div>
        )}

        {visibleFields.phone && (
          <div className="material-form-field">
            <PhoneNumber
              onPhoneChange={this.onPhoneChange}
              onIsValidPhoneNumberChange={this.onIsValidPhoneNumberChange}
            />
          </div>
        )}

        {visibleFields.email && (
          <div className="material-form-field">
            <input
              type="email"
              autoComplete="off"
              id="email"
              name="email"
              value={email}
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_SignupForm_Email_Focused", {
                  phonenumber: this.phoneNumber,
                })
              }
              onChange={this.onChange}
            />
            <label className="material-form-field-label" htmlFor="email">
              {t("signup:email")}
            </label>
          </div>
        )}

        {visibleFields.password && (
          <div className="material-form-field">
            <input
              autoComplete="off"
              autoFocus={
                !visibleFields.fullname &&
                !visibleFields.phone &&
                !visibleFields.email &&
                visibleFields.password
              }
              type="password"
              id="password"
              name="password"
              placeholder={t("common:password_placeholder")}
              required
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_Password_Focused", {
                  phonenumber: this.phoneNumber,
                })
              }
              minLength={6}
              onChange={this.onPasswordChange}
              value={password}
            />
            <label className="material-form-field-label" htmlFor="password">
              {t("signup:password")}
            </label>
          </div>
        )}

        {visibleFields.invitationCode && (
          <div className="material-form-field">
            <input
              type="text"
              autoComplete="off"
              id="invitationCode"
              name="invitationCode"
              onFocus={() =>
                Analytika.fireEvent(
                  "Idtn_Web_SignupForm_Invitation_Code_Focused",
                  { phonenumber: this.phoneNumber }
                )
              }
              onChange={this.onChange}
            />
            <label
              className="material-form-field-label"
              htmlFor="invitationCode"
            >
              {t("signup:invitation_code")}
            </label>
          </div>
        )}

        <div className="signup-terms-text">
          <Trans i18nKey="signup:terms_statement" t={t}>
            By clicking on &apos;Sign up&apos; you agree to Careem&apos;s
            <a
              href="https://www.careem.com/en-ae/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            and acknowledge that you have read the
            <a
              href="https://www.careem.com/en-ae/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Trans>
        </div>
        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("signup:button")}
          name="verify-recover-btn"
          disabled={
            submitting ||
            this.props.isInvalidPassword ||
            (visibleFields.fullname && fullname?.length === 0) ||
            (visibleFields.email && email?.length === 0) ||
            (visibleFields.password && password?.length < 6) ||
            (visibleFields.phone && !this.state.isValidPhoneNumber)
          }
        />

        <div
          id="alternative-login"
          style={{
            display:
              visibleFields.appleButton ||
              visibleFields.googleButton
                ? "flex"
                : "none",
            flexDirection: "column",
          }}
        >
          <div id="or">{t("common:or")}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {visibleFields.appleButton && (
              <AppleButton onChange={this.onAppleIdChange.bind(this)} />
            )}

            {visibleFields.googleButton && (
              <GoogleButton onChange={this.onGoogleIdChange.bind(this)} />
            )}

          </div>
        </div>

        <p className="footer-link col-xs-12">
          {t("signup:careeem_terms_statement")}&nbsp;
          <button
            className="button-looks-like-link"
            type="button"
            onClick={this.onTermsClickHandler.bind(this)}
          >
            {t("signup:terms_conditions")}
          </button>
        </p>

        <ReCaptcha />
      </form>
    );
  }

  renderEnhanced() {
    const {
      t,
      error,
      submitting,
      visibleFields,
      fullname,
      email,
      password,
    } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <Helmet>
          <title>Signup | Careem</title>
        </Helmet>

        <h1 className="heading-h1-express center express-common">
          {t("signup:titleShort")}{" "}
        </h1>

        {visibleFields.fullname && (
          <>
            <div className="express-form-field-label show express-common">
              {t("signup:full_name")}
            </div>
            <div className="express-form-field express-common">
              <input
                type="text"
                autoFocus={visibleFields.fullname}
                autoComplete="off"
                id="fullname"
                name="fullname"
                required
                onFocus={() =>
                  Analytika.fireEvent("Idtn_Web_SignupForm_Fullname_Focused", {
                    phonenumber: this.phoneNumber,
                  })
                }
                onChange={this.onChange}
                value={fullname}
              />
            </div>
          </>
        )}

        {visibleFields.phone && (
          <>
            <div className="express-form-field-label show express-common">
              {t("signup:phone_number")}
            </div>
            <div className="material-form-field express-form-field">
              <PhoneNumber
                onPhoneChange={this.onPhoneChange}
                onIsValidPhoneNumberChange={this.onIsValidPhoneNumberChange}
                containerClassName="express-intl-tel-input"
              />
            </div>
          </>
        )}

        {visibleFields.email && (
          <div className="material-form-field">
            <input
              type="email"
              autoComplete="off"
              id="email"
              name="email"
              value={email}
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_SignupForm_Email_Focused", {
                  phonenumber: this.phoneNumber,
                })
              }
              onChange={this.onChange}
            />
            <label className="material-form-field-label" htmlFor="email">
              {t("signup:email")}
            </label>
          </div>
        )}

        {visibleFields.password && (
          <>
            <div className="express-form-field-label show express-common">
              {t("signup:password")}
            </div>
            <div className="express-form-field express-common">
              <input
                autoComplete="off"
                autoFocus={
                  !visibleFields.fullname &&
                  !visibleFields.phone &&
                  !visibleFields.email &&
                  visibleFields.password
                }
                type="password"
                id="password"
                name="password"
                placeholder="******"
                required
                onFocus={() =>
                  Analytika.fireEvent("Idtn_Web_Password_Focused", {
                    phonenumber: this.phoneNumber,
                  })
                }
                minLength={6}
                onChange={this.onPasswordChange}
                value={password}
              />
            </div>
            <div className="express-extra-text show">
              {t("signup:password_placeholder")}
            </div>
          </>
        )}

        {error && <div className="invalid show">{error}</div>}

        {visibleFields.invitationCode && (
          <div className="material-form-field">
            <input
              type="text"
              autoComplete="off"
              id="invitationCode"
              name="invitationCode"
              onFocus={() =>
                Analytika.fireEvent(
                  "Idtn_Web_SignupForm_Invitation_Code_Focused",
                  { phonenumber: this.phoneNumber }
                )
              }
              onChange={this.onChange}
            />
            <label
              className="material-form-field-label"
              htmlFor="invitationCode"
            >
              {t("signup:invitation_code")}
            </label>
          </div>
        )}

        <div className="express-terms-statement">
          <Trans i18nKey="signup:terms_privacy" t={t}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            By clicking on 'Sign up' you agree to Careem's
            <a
              href="https://www.careem.com/en-ae/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            and acknowledge that you have read the
            <a
              href="https://www.careem.com/en-ae/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Trans>
        </div>
        <input
          className="btn-express btn-express-primary"
          type="submit"
          value={submitting ? t("common:please_wait") : t("signup:button")}
          name="verify-recover-btn"
          disabled={
            submitting ||
            this.props.isInvalidPassword ||
            (visibleFields.fullname && fullname?.length === 0) ||
            (visibleFields.email && email?.length === 0) ||
            (visibleFields.password && password?.length < 6) ||
            (visibleFields.phone && !this.state.isValidPhoneNumber)
          }
        />

        <div
          id="alternative-login"
          style={{
            display:
              visibleFields.appleButton ||
              visibleFields.googleButton
                ? "flex"
                : "none",
            flexDirection: "column",
          }}
        >
          <div id="or">{t("common:or")}</div>
          <div
            style={{
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
            }}
          >
            {visibleFields.appleButton && (
              <AppleButton onChange={this.onAppleIdChange.bind(this)} />
            )}

            {visibleFields.googleButton && (
              <GoogleButton onChange={this.onGoogleIdChange.bind(this)} />
            )}

          </div>
        </div>

        <ReCaptcha />
      </form>
    );
  }

  render() {
    const { isEnhancedFormEnabled } = this.props;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }
}

SignupForm.propTypes = {
  t: Types.func.isRequired,
  visibleFields: Types.object.isRequired,
  onAppleIdChange: Types.func.isRequired,
  onGoogleIdChange: Types.func.isRequired,
  onChange: Types.func.isRequired,
  onPasswordChange: Types.func.isRequired,
  onPhoneChange: Types.func.isRequired,
  onSubmit: Types.func.isRequired,
  isInvalidPassword: Types.bool,
  fullname: Types.string,
  email: Types.string,
  password: Types.string,
  phoneNumber: Types.string,
  error: Types.any,
  submitting: Types.bool,
  flowType: Types.string,
  isEnhancedFormEnabled: Types.bool,
};

export default withTranslation()(SignupForm);
