import { Trans, withTranslation } from "react-i18next";
import React from "react";
import GotoCareemAppButton from "./GotoCareemAppButton";
import ThumbLogo from "../../images/icn_thumbs_up.svg";

export class VerificationTokenAlreadyUsed extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="email-verified-container">
        <div className="mail-box-container">
          <img src={ThumbLogo} className="thumb-logo" alt="Thumb logo" />
        </div>
        <h1>
          <b>{t("email_verification:token_used_title")}</b>
        </h1>
        <br />
        <p className="email-message-container">
          {t("email_verification:token_used")}
        </p>
        <GotoCareemAppButton />
        <h5>
          <Trans i18nKey="email_verification:contact_us" t={t}>
            Have further questions?
            <a href="https://help.careem.com/hc/requests/new">Get in touch</a>.
          </Trans>
        </h5>
      </div>
    );
  }
}

export default withTranslation()(VerificationTokenAlreadyUsed);
