import React from "react";
import Types from "prop-types";
import LanguageSelector from "../../LanguageSelector";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Analytika from "../../../analytika";
import { GalileoContext } from "../../../galileo";
import ButtonEnhanced from "../../common/enhanced/ButtonEnhanced";
import eventAnalytics from "../../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../../utils/constants";
import TryAnotherWayImg from "../../../images/try-another-way.svg";

export class PasswordLoginForm extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.PASSWORD);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.PASSWORD,
      }
    );
    Analytika.fireEvent("Idtn_Web_PasswordLogin_View", {
      phonenumber: props.fullPhoneNumber,
    });
    this.onPasswordChange = props.onPasswordChange;
    this.onTryAnotherClickHandler = props.onTryAnotherClickHandler;
    this.isTryAnotherWayVisible = props.isTryAnotherWayVisible;
    this.onSubmit = props.onSubmit;
    this.signupEndpoint = props.signupEndpoint;
    this.recoveryEndpoint = props.recoveryEndpoint;

    this.createAccount = this.createAccount.bind(this);
  }

  static contextType = GalileoContext;

  forgotPassword() {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_version,
      {
        option_name: "forgot_password",
      }
    );
    Analytika.fireEvent("Idtn_Web_PasswordLogin_Forget_Clicked", {
      phonenumber: this.props.fullPhoneNumber,
    });
    window.location.href = this.recoveryEndpoint;
  }

  createAccount() {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_version,
      {
        option_name: "create_account",
      }
    );
    Analytika.fireEvent("Idtn_Web_PasswordLogin_Signup_Clicked", {
      phonenumber: this.props.fullPhoneNumber,
    });
    window.location.href = this.signupEndpoint;
  }

  render() {
    const { isEnhancedFormEnabled } = this.context;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }

  renderClassic() {
    const { t, error, submitting } = this.props;

    return (
      <form data-request-type="password_submit" onSubmit={this.onSubmit}>
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        <div className="help-lang-container">
          <LanguageSelector />
        </div>
        <h1>{t("login:password_title")}</h1>

        {error && <div className="invalid show">{error}</div>}

        <div className="material-form-field">
          <input
            autoComplete="off"
            type="password"
            id="password"
            name="password"
            onFocus={() =>
              Analytika.fireEvent("Idtn_Web_PasswordLogin_Password_Focus", {
                phonenumber: this.props.fullPhoneNumber,
              })
            }
            onChange={this.onPasswordChange}
          />
          <label className="material-form-field-label" htmlFor="password">
            {t("signup:password")}
          </label>
        </div>

        {this.isTryAnotherWayVisible && (
          <div
            className="btn-try-another-way"
            name="btn-try-another-way"
            onClick={this.onTryAnotherClickHandler}
          >
            <img src={TryAnotherWayImg} alt="TryAnotherWayImg" width="20px" />
            {t("login:try-another-way")}
          </div>
        )}
        {!this.isTryAnotherWayVisible && (
          <>
            <div className="info center">
              <button
                className="button-looks-like-link"
                onClick={this.forgotPassword.bind(this)}
              >
                {t("login:forgot_password")}
              </button>
            </div>
            <div className="row">
              <p className="footer-link col-xs-12">
                {t("login:new_to_careem")}
                <button
                  className="button-looks-like-link"
                  onClick={this.createAccount.bind(this)}
                >
                  {t("login:create_account")}
                </button>
              </p>
            </div>
          </>
        )}
        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="verify-recover-btn"
          disabled={submitting}
        />
        {this.isTryAnotherWayVisible && (
          <input
            className="btn-careem-tertiary"
            type="button"
            value={t("login:create_account")}
            name="btn-create-account"
            onClick={this.createAccount}
          />
        )}

        <ReCaptcha />
      </form>
    );
  }

  renderEnhanced() {
    const { t, error, submitting } = this.props;

    return (
      <form
        data-submit-event="Idtn_Web_PasswordLogin_Submit"
        onSubmit={this.onSubmit}
      >
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        <h1 className="heading-h1-express center express-common">
          {t("login:confirmation_name_title")}
        </h1>

        <label
          className="express-form-field-label show express-common"
          htmlFor="password"
        >
          {t("signup:password")}
        </label>
        <div
          className={`express-form-field express-common  ${error && "show"}`}
        >
          <input
            autoComplete="off"
            type="password"
            id="password"
            name="password"
            onFocus={() =>
              Analytika.fireEvent("Idtn_Web_PasswordLogin_Password_Focus", {
                phonenumber: this.props.fullPhoneNumber,
              })
            }
            onChange={this.onPasswordChange}
          />
        </div>
        {error && <div className="invalid show">{error}</div>}
        <ButtonEnhanced
          className="express-form-distant-item"
          name="verify-recover-btn"
          disabled={submitting}
          type="submit"
          fullWidth
          btnType="primary"
        >
          {submitting ? t("common:please_wait") : t("login:login-button")}
        </ButtonEnhanced>

        <div className="row express-form-distant-item center">
          <p className="express-text-secondary express-common">
            {t("login:forgot_password")}{" "}
            <button
              className="button-like-link express-link-action"
              onClick={this.forgotPassword.bind(this)}
            >
              {t("login:reset_password")}
            </button>
          </p>
        </div>

        <ReCaptcha />
      </form>
    );
  }
}

PasswordLoginForm.propTypes = {
  t: Types.func.isRequired,
  onPasswordChange: Types.func.isRequired,
  onTryAnotherClickHandler: Types.func.isRequired,
  isTryAnotherWayVisible: Types.bool.isRequired,
  onSubmit: Types.func.isRequired,
  signupEndpoint: Types.string,
  recoveryEndpoint: Types.string,
  fullPhoneNumber: Types.string,
  error: Types.string,
  submitting: Types.bool,
};
export default withTranslation()(PasswordLoginForm);
