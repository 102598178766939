import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { isAndroid, isIOS } from "../../../../utils/getMobileOS";
import Analytika from "../../../../analytika";
import eventAnalytics from "../../../../analytikaV2";
import RemainingTime from "./RemainingTime";
import ChangePhone from "./ChangePhone";
import styles from "../styles";
import { withTranslation } from "react-i18next";
import PromoLine from "../BannerPromoLine";

function MobileView(props) {
  const {
    classes,
    phoneNumber,
    onRetry,
    deeplink,
    onChangePhone,
    t,
    promoValue,
    promoType,
    promoOutcome,
  } = props;

  const linkRef = useRef();
  const handleOpenApp = () => {
    Analytika.fireEvent("open_careem_app_button_clicked");
    eventAnalytics.publish("pyck_tap_ck_button", null, {
      flow: "identity",
      button_name: "open_careem_app",
      screen_name: "await_verification",
    });
    if (!linkRef.current) return;

    if (isIOS || isAndroid) {
      linkRef.current.href = deeplink;
      linkRef.current.click();
    }
  };

  return (
    <Fragment>
      <Fragment>
        <div className={classes.title}>
          <Typography component="h1" className={classes.formTitle}>
            {t("one_click_checkout:confirm_via_careem_app")}
          </Typography>
          <ChangePhone
            phoneNumber={phoneNumber}
            onChangePhone={onChangePhone}
          />
        </div>
        {promoValue && (
          <PromoLine
            promoValue={promoValue}
            promoType={promoType}
            promoOutcome={promoOutcome}
          />
        )}
        <Button
          type="button"
          size="large"
          fullWidth
          variant="contained"
          classes={{
            root: clsx(classes.sendAgainButton, classes.openCareemAppButton),
          }}
          onClick={handleOpenApp}
        >
          {t("common:open_careem_app")}
        </Button>
      </Fragment>
      <Box display="none">
        <a
          ref={linkRef}
          rel="nofollow"
          href="#placeHolder"
          target="_blank"
          name="hidden deep link"
        >
          deep link
        </a>
      </Box>
      <RemainingTime onRetry={onRetry} />
    </Fragment>
  );
}

MobileView.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  deeplink: PropTypes.string.isRequired,
  promoValue: PropTypes.string,
  promoType: PropTypes.string,
  promoOutcome: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(MobileView));
