import React, { useEffect } from "react";
import Types from "prop-types";
import { Helmet } from "react-helmet";
import { withTranslation, Trans } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { ReCaptcha } from "../../Guard";
import PhoneNumber from "../../common/PhoneNumber";
import styles from "./styles";
import Analytika from "../../../analytika";
import PromoLine from "../view/InlinePromoLine";

function OneClickPhoneLoginForm(props) {
  const {
    t,
    i18n,
    classes,
    error,
    submitting,
    merchant,
    onSubmit,
    onPhoneChange,
    promoValue,
    promoType,
    promoOutcome,
  } = props;

  useEffect(() => {
    Analytika.fireEvent("Idtn_Web_OneClick_PhoneLogin_View");
  }, []);

  return (
    <form onSubmit={onSubmit} className={classes.sizeControl}>
      <Helmet>
        <title>Login | Careem</title>
      </Helmet>
      <div dir={i18n.dir()} className={classes.title}>
        <Typography component="h1" className={classes.formTitle}>
          {t("one_click_checkout:title")}
        </Typography>
        <Typography component="h3" className={classes.formSubtitle}>
          {promoValue ? 
          <div className={classes.container}> 
            <PromoLine 
            promoValue={promoValue}
            promoType={promoType}
            promoOutcome={promoOutcome}/> 
          </div>
          : <Trans t={t} i18nKey="one_click_checkout:subtitle">
          Sign in to Careem to continue checkout
            {merchant && (
              <span>
                on
                <span>{{ merchantName: merchant }}</span>
              </span>
            )}
          </Trans>}
        </Typography>
      </div>
      <div className="material-form-field">
        <PhoneNumber onPhoneChange={onPhoneChange} error={error} />
      </div>
      <input
        className="btn-careem-aurora"
        type="submit"
        value={submitting ? t("common:please_wait") : t("common:continue")}
        name="verify-recover-btn"
        disabled={submitting}
      />
      <ReCaptcha />
    </form>
  );
}

OneClickPhoneLoginForm.propTypes = {
  classes: Types.object.isRequired,
  t: Types.func,
  i18n: Types.object,
  onPhoneChange: Types.func.isRequired,
  onSubmit: Types.func.isRequired,
  error: Types.string,
  merchant: Types.string,
  submitting: Types.bool.isRequired,
  promoValue: Types.string,
  promoType: Types.string,
  promoOutcome: Types.string,
};

export default withTranslation()(withStyles(styles)(OneClickPhoneLoginForm));
