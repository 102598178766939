import React from "react";
import HelpCenterIcon from "../../images/help_center_Icon.svg";
import Types from "prop-types";
import { withTranslation } from "react-i18next";
import eventAnalytics from "../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../utils/constants";

class HelpCenterButton extends React.Component {
  state = {};
  render() {
    const { t, href, target = "_blank" } = this.props;
    return (
      <button
        type="button"
        className="help-center-button"
        onClick={(e) => {
          e.preventDefault();
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
            { button_name: "help_center" },
            ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version
          );
          window.open(href, target);
        }}
      >
        <img
          src={HelpCenterIcon}
          className="help-center-icon"
          alt="Help Center Icon"
        />
        <span> {t("common:help_center")}</span>
      </button>
    );
  }
}

HelpCenterButton.propTypes = {
  t: Types.func.isRequired,
  href: Types.string.isRequired,
  target: Types.string,
};

export default withTranslation()(HelpCenterButton);
