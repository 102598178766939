import React from "react";
import Types from "prop-types";
import LanguageSelector from "../../LanguageSelector";
import TryAnotherWayImg from "../../../images/try-another-way.svg";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Analytika from "../../../analytika";
import eventAnalytics from "../../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../../utils/constants";

export class VerifyEmailLoginForm extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.VERIFY_EMAIL_FORM_VIEW);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.VERIFY_EMAIL_FORM_VIEW,
      }
    );
    Analytika.fireEvent("Idtn_Web_EmailLogin_View", {
      phonenumber: props.fullPhoneNumber,
    });
    this.onEmailChange = props.onEmailChange;
    this.onTryAnotherClickHandler = props.onTryAnotherClickHandler;
    this.isTryAnotherWayVisible = props.isTryAnotherWayVisible;
    this.onSubmit = props.onSubmit;

    this.createAccount = props.createAccount;
    this.maskedValue = props.maskedValue;
  }

  render() {
    return this.renderClassic();
  }

  renderClassic() {
    const { t, error, submitting } = this.props;

    return (
      <form data-request-type="Email_submit" onSubmit={this.onSubmit}>
        <Helmet>
          <title>Verify Email | Careem</title>
        </Helmet>

        <div className="help-lang-container">
          <LanguageSelector />
        </div>
        <h1>{t("login:verify_email_title")}</h1>

        {error && <div className="invalid show">{error}</div>}

        <div className="material-form-field">
          <input
            autoComplete="off"
            type="text"
            id="verify_name"
            name="verify_name"
            onChange={this.onEmailChange}
          />
          <label className="material-form-field-label" htmlFor="verify_name">
            {t("login:lbl_verify_email")}
          </label>
        </div>
        <div className="verify-email-hint">
          Your email as per our records is {this.maskedValue}
        </div>

        {this.isTryAnotherWayVisible && (
          <div
            className="btn-try-another-way"
            name="btn-try-another-way"
            onClick={this.onTryAnotherClickHandler}
          >
            <img src={TryAnotherWayImg} alt="TryAnotherWayImg" width="20px" />
            {t("login:try-another-way")}
          </div>
        )}

        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="btn-verify-email"
          disabled={submitting}
        />
        <input
          className="btn-careem-tertiary"
          type="button"
          value={t("login:create_account")}
          name="btn-create-account"
          onClick={this.createAccount}
        />

        <ReCaptcha />
      </form>
    );
  }
}

VerifyEmailLoginForm.propTypes = {
  t: Types.func.isRequired,
  onEmailChange: Types.func.isRequired,
  onTryAnotherClickHandler: Types.func.isRequired,
  isTryAnotherWayVisible: Types.bool.isRequired,
  maskedValue: Types.string.isRequired,
  onSubmit: Types.func.isRequired,
  createAccount: Types.func.isRequired,
  fullPhoneNumber: Types.string,
  error: Types.string,
  submitting: Types.bool,
};
export default withTranslation()(VerifyEmailLoginForm);
