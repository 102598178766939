import React from "react";
import Types from "prop-types";
import LanguageSelector from "../LanguageSelector";
import { fetchReCaptchaToken, ReCaptcha } from "../Guard";
import { Trans, withTranslation } from "react-i18next";
import { Redirect, withRouter } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ARGUS_APM } from "../../apm";
import Analytika from "../../analytika";
import { GalileoContext } from "../../galileo";
import SuccessTransactionLogo from "../../images/successTransaction.svg";
import eventAnalytics from "../../analytikaV2";
import { ANALYTIKA_EVENTS, HELP_CENTER_LINK } from "../../utils/constants";
import HelpCenterButton from "../common/HelpCenterButton";
import { bugsnagInstance } from "../../bugsnag";

class ChallengeFullName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      passwordUpdatedDone: false,
      fullName: "",
      error: null,
      phoneNumber: props.phoneNumber,
    };
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.FORGOT_PASSWORD);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.FORGOT_PASSWORD,
      }
    );
    Analytika.fireEvent("Idtn_Web_NameChellenge_View", {
      phonenumber: props.phoneNumber,
    });
    this.onSubmit = this.onSubmit.bind(this);
  }

  static contextType = GalileoContext;

  onChange(event) {
    this.setState({ fullName: event.target.value });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.fullName.length !== prevState.fullName.length) {
      let currentAction = -1;
      if (this.state.fullName.length > prevState.fullName.length) {
        currentAction = 1;
      } else if (this.state.fullName.length < prevState.fullName.length) {
        currentAction = 0;
      }

      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_NAME.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_NAME.event_version,
        {
          type_character: currentAction,
          name_entered: this.state.fullName,
        });
    }
  }
  backToLogin() {
    window.location.href = process.env.REACT_APP_IDENTITY_REDIRECT;
    Analytika.fireEvent("Idtn_Web_NameChellenge_Back_To_Login", {
      phonenumber: this.props.phoneNumber,
    });
  }
  onSubmit(event) {
    event.preventDefault();
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
      { button_name: "continue" }
    );
    this.setState({ submitting: true });

    let recaptchaPromise = fetchReCaptchaToken("forgot_password_email");
    Analytika.fireEvent("Idtn_Web_NameChellenge_Submit", {
      phonenumber: this.state.phoneNumber,
    });

    recaptchaPromise
      .then((token) => {
        Analytika.fireEvent("Idtn_Web_NameChellenge_Submit_Success", {
          phonenumber: this.state.phoneNumber,
        });
        this.props.verifyChallenge({
          recaptcha: token,
          answer: this.state.fullName,
        });
      })
      .then(() => {
        Analytika.fireEvent("Idtn_Web_RestPasswordLink_View", {
          phonenumber: this.state.phoneNumber,
        });
        this.setState({ submitting: false, passwordUpdatedDone: true });
      })
      .catch((err) => {
        bugsnagInstance.notify(err);
        Analytika.fireEvent("Idtn_Web_NameChellenge_Submit_Fail", {
          phonenumber: this.state.phoneNumber,
        });
        ARGUS_APM.captureError(err);
        this.setState({
          submitting: false,
          passwordUpdatedDone: false,
          error: err.response?.data,
        });
      });
  }

  render() {
    const { isEnhancedFormEnabled } = this.context;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }

  renderClassic() {
    const { t, challenge } = this.props;

    if (challenge == null) {
      return <Redirect to="/session_unknown" />;
    }

    const { error, passwordUpdatedDone, submitting } = this.state;
    if (passwordUpdatedDone) {
      return (
        <div>
          <CheckCircleOutlineIcon className="largeIcon successColor" />
          <h1>
            <Trans i18nKey="reset_password:reset_link_sent">
              A link to reset your password has been sent to you.
            </Trans>
          </h1>
          <div className="terms-text">
            <Trans i18nKey="reset_password:reset_password_instructions_message">
              Please check your email or SMS inbox for instructions to create a
              new password.
            </Trans>
          </div>
          <h3>
            <button
              className="button-looks-like-link"
              type="button"
              onClick={this.backToLogin.bind(this)}
            >
              <Trans i18nKey="reset_password:go_back_login">
                Go back to login.
              </Trans>
            </button>
          </h3>
        </div>
      );
    } else {
      return (
        <form onSubmit={this.onSubmit.bind(this)}>
          <div className="help-lang-container">
            <HelpCenterButton href={HELP_CENTER_LINK.FORGET_PASSWORD} />
            <LanguageSelector />
          </div>
          <h1>{t("recover:forgot_password")}</h1>
          <h3>{t("recover:please_enter_full_name")}</h3>

          {error && (
            <div className="invalid show">
              {t(["error:" + error.message, "error:default"])}
            </div>
          )}

          <div className="material-form-field">
            <input
              type="text"
              autoFocus
              autoComplete="off"
              id="fullname"
              name="fullname"
              placeholder={challenge?.hint.maskedFullName}
              dir={challenge?.hint.dir}
              required
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_NameChellenge_Name_Focused", {
                  phonenumber: this.state.phoneNumber,
                })
              }
              onChange={this.onChange.bind(this)}
            />
            <label className="material-form-field-label" htmlFor="fullname">
              {t("recover:full_name")}
            </label>
          </div>

          <input
            className="btn-careem"
            type="submit"
            value={submitting ? t("common:please_wait") : t("common:continue")}
            name="verify-recover-btn"
            disabled={submitting}
          />

          <ReCaptcha />
        </form>
      );
    }
  }

  renderEnhanced() {
    const { t, challenge } = this.props;

    if (challenge == null) {
      return <Redirect to="/session_unknown" />;
    }

    const { error, passwordUpdatedDone, submitting } = this.state;
    if (passwordUpdatedDone) {
      return (
        <div>
          <div className="express-success-screen-line">
            <img
              src={SuccessTransactionLogo}
              alt="Welcome back!"
              style={{ width: 325 }}
            />
          </div>
          <h1 className="heading-h1-express center express-common">
            <Trans i18nKey="reset_password:reset_link_sent">
              A link to reset your password has been sent to you.
            </Trans>
          </h1>
          <div className="express-extra-text show express-success-screen-line">
            <Trans i18nKey="reset_password:reset_password_instructions_message">
              Please check your email or SMS inbox for instructions to create a
              new password.
            </Trans>
          </div>
          <h3>
            <button
              className="btn-express btn-express-primary express-form-distant-item"
              onClick={this.backToLogin.bind(this)}
            >
              <Trans i18nKey="login:login-button">Go back to login.</Trans>
            </button>
          </h3>
        </div>
      );
    } else {
      return (
        <form onSubmit={this.onSubmit.bind(this)}>
          <h1 className="heading-h1-express center express-common">
            {t("recover:forgot_password")}
          </h1>
          <h3 className="heading-h3-express center express-common">
            {t("recover:please_enter_full_name")}
          </h3>

          <label
            className="express-form-field-label show express-common"
            htmlFor="fullname"
          >
            {t("recover:full_name")}
          </label>

          <div
            className={`express-form-field express-common  ${error && "show"}`}
          >
            <input
              type="text"
              autoFocus
              autoComplete="off"
              id="fullname"
              name="fullname"
              placeholder={challenge.hint.maskedFullName}
              dir={challenge.hint.dir}
              required
              onFocus={() =>
                Analytika.fireEvent("Idtn_Web_NameChellenge_Name_Focused", {
                  phonenumber: this.state.phoneNumber,
                })
              }
              onChange={this.onChange.bind(this)}
            />
          </div>

          {error && (
            <div className="invalid show">
              {t(["error:" + error.message, "error:default"])}
            </div>
          )}

          <input
            className="btn-express btn-express-primary express-form-distant-item"
            type="submit"
            value={submitting ? t("common:please_wait") : t("common:continue")}
            name="verify-recover-btn"
            disabled={submitting}
          />

          <ReCaptcha />
        </form>
      );
    }
  }
}

ChallengeFullName.propTypes = {
  verifyChallenge: Types.func.isRequired,
  challenge: Types.object,
  t: Types.func.isRequired,
  history: Types.object.isRequired,
  phoneNumber: Types.string,
};

export default withRouter(withTranslation()(ChallengeFullName));
export const ChallengeFullNameUnwrapped = ChallengeFullName;
