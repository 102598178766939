import React, { useContext } from "react";
import {
  AppBar,
  withWidth,
  Toolbar,
  isWidthDown,
  IconButton,
} from "@material-ui/core";
import Types from "prop-types";
import { useLocation } from "react-router-dom";
import HeadsetOutlinedIcon from "@material-ui/icons/HeadsetOutlined";
import CareemBlackLogo from "../../../images/careemLogoBlack.svg";
import LanguageSelector from "../../LanguageSelector";
import { IdpClientContext } from "../../../idpClient";
import { useTranslation } from "react-i18next";
import ButtonEnhanced from "./ButtonEnhanced";

const getHelpCenterUrl = (pathName) => {
  switch (pathName) {
    case "/login":
      return "https://help.careem.com/hc/en-us/articles/360001609587-What-if-I-didn-t-receive-a-verification-code";
    case "/signup":
      return "https://help.careem.com/hc/en-us/articles/360001609507-Creating-an-account";
    case "/session_init":
      return "https://help.careem.com/hc/en-us/articles/360001592468-I-forgot-my-password";
    default:
      return null;
  }
};

const HeaderEnhanced = ({ width }) => {
  const { logoUri } = useContext(IdpClientContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const helpCenterUrl = getHelpCenterUrl(pathname);

  const isSmallDevice = isWidthDown("xs", width);

  return (
    <AppBar color="transparent" position="static">
      <Toolbar
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "2vh 10vw",
        }}
      >
        <div>
          {!isSmallDevice && (
            <img
              style={{ width: 105, margin: 0 }}
              src={logoUri || CareemBlackLogo}
              alt="Careem logo"
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          {helpCenterUrl &&
            (isSmallDevice ? (
              <IconButton
                className="express-icon-button"
                size="medium"
                style={{ marginRight: "20px" }}
                onClick={() => window.location.assign(helpCenterUrl)}
              >
                <HeadsetOutlinedIcon />
              </IconButton>
            ) : (
              <ButtonEnhanced
                btnType="tertiary"
                startIcon={<HeadsetOutlinedIcon />}
                size="large"
                onClick={() => window.location.assign(helpCenterUrl)}
                style={{ marginRight: "20px" }}
              >
                {t("common:help_center")}
              </ButtonEnhanced>
            ))}
          <LanguageSelector isEnhancedFormEnabled />
        </div>
      </Toolbar>
    </AppBar>
  );
};

HeaderEnhanced.propTypes = {
  width: Types.string,
};

export default withWidth()(HeaderEnhanced);
