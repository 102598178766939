import React from "react";
import Types from "prop-types";
import LanguageSelector from "../../LanguageSelector";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import PhoneNumber from "../../common/PhoneNumber";
import AppleButton from "../../common/AppleButton";
import GoogleButton from "../../common/GoogleButton";
import Analytika from "../../../analytika";
import ButtonEnhanced from "../../common/enhanced/ButtonEnhanced";
import eventAnalytics from "../../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../../utils/constants";

export class PhoneLoginForm extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.WELCOME);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.WELCOME,
      }
    );
    Analytika.fireEvent("Idtn_Web_PhoneLogin_View");
    Analytika.fireEvent("PY_1C_Identity_ViewEnterPhoneScreen");
  }

  getSocialLoginHint() {
    const { visibleFields, t } = this.props;
    if (visibleFields.appleButton) {
      return t("login:already_signed_up_apple");
    }
  }

  renderClassic() {
    const { t, error, submitting, visibleFields } = this.props;
    return (
      <form data-request-type="phone_submit" onSubmit={this.props.onSubmit}>
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>
        <div className="help-lang-container">
          <LanguageSelector />
        </div>
        <h1>{t("login:title")}</h1>

        {error && <div className="invalid show">{error}</div>}
        <div className="material-form-field">
          <PhoneNumber onPhoneChange={this.props.onPhoneChange} />
        </div>
        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="verify-recover-btn"
          disabled={submitting}
        />

        <div
          id="alternative-login"
          style={{
            display:
              visibleFields.appleButton ||
              visibleFields.googleButton
                ? "flex"
                : "none",
            flexDirection: "column",
          }}
        >
          <div id="or">{t("common:or")}</div>
          <div className="fb-hint center">{this.getSocialLoginHint()}</div>
          <div className="social-button-container">
            {visibleFields.appleButton && (
              <AppleButton onChange={this.props.onAppleTokenChanged} />
            )}

            {visibleFields.googleButton && (
              <GoogleButton onChange={this.props.onGoogleTokenChanged} />
            )}
          </div>
        </div>
        <ReCaptcha />
      </form>
    );
  }

  renderEnhanced() {
    const { t, error, submitting, phoneState } = this.props;
    const { fullPhoneNumber } = phoneState;
    return (
      <form
        data-submit-event="Idtn_Web_PhoneLogin_Submit"
        onSubmit={this.props.onSubmit}
      >
        <div>
          <Helmet>
            <title>Login | Careem</title>
          </Helmet>
          <h1 className="heading-h1-express center express-common">
            {t("login:enter_phone_title")}
          </h1>
          <h3 className="heading-h3-express center express-common">
            {t("login:enter_phone_description")}
          </h3>
          <div className="express-form-field-label show express-common">
            {t("login:enter_phone_field_label")}
          </div>
          <div className={`express-form-field ${error && "show"}`}>
            <PhoneNumber
              onPhoneChange={this.props.onPhoneChange}
              containerClassName="express-intl-tel-input"
            />
          </div>
          {error && <div className="invalid show express-common">{error}</div>}
          <ButtonEnhanced
            name="verify-recover-btn"
            disabled={!fullPhoneNumber || submitting}
            type="submit"
            fullWidth
            btnType="primary"
          >
            {submitting ? t("common:please_wait") : t("common:continue")}
          </ButtonEnhanced>
        </div>
        <ReCaptcha />
      </form>
    );
  }

  render() {
    const { isEnhancedFormEnabled } = this.props;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }
}

PhoneLoginForm.propTypes = {
  t: Types.func.isRequired,
  state: Types.object,
  onPhoneChange: Types.func.isRequired,
  onSubmit: Types.func.isRequired,
  visibleFields: Types.object,
  error: Types.any,
  submitting: Types.any,
  onAppleTokenChanged: Types.func,
  onGoogleTokenChanged: Types.func,
  isEnhancedFormEnabled: Types.bool,
  phoneState: Types.shape({
    countryCode: Types.string,
    fullPhoneNumber: Types.string,
    isValid: Types.bool,
    nationalPhoneNumber: Types.string,
  }),
};
export default withTranslation()(PhoneLoginForm);
