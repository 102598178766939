import React, { useEffect } from "react";
import Types from "prop-types";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/styles";

import ErrorLogo from "../../../images/Error.png";
import PlaystoreLogo from "../../../images/app-store/google.svg";
import AppstoreLogo from "../../../images/app-store/apple.svg";
import Analytika from "../../../analytika";
import { APP_STORE_URL, PLAY_STORE_URL } from "../../../utils/constants";
import styles from "./styles";

function OneClickErrorView(props) {
  const { classes, heading, subHeading, description, showUpdateButton } = props;

  useEffect(() => {
    Analytika.fireEvent("error_screen_opened");
  }, []);

  return (
    <form className={classes.sizeControl}>
      <Helmet>
        <title>Something went wrong</title>
      </Helmet>
      <Box display="flex">
        <Box m="auto">
          <img src={ErrorLogo} className="error-logo" alt="Error logo" />
        </Box>
      </Box>
      <h1 className="heading-h1-aurora">{heading} </h1>
      <h3>{subHeading}</h3>
      <p className="text-description">{description}</p>
      {showUpdateButton && (
        <Box display="flex" justifyContent="center" gridGap={5} mt={3}>
          <a href={PLAY_STORE_URL} rel="noopener noreferrer">
            <img
              src={PlaystoreLogo}
              className="playstore-logo"
              alt="playstore logo"
              height="40px"
              width="140px"
            />
          </a>
          <a href={APP_STORE_URL} rel="noopener noreferrer">
            <img
              src={AppstoreLogo}
              className={classes.appStoreImg}
              alt="app store logo"
              height="40px"
              width="140px"
            />
          </a>
        </Box>
      )}
    </form>
  );
}

OneClickErrorView.propTypes = {
  classes: Types.object.isRequired,
  heading: Types.string.isRequired,
  subHeading: Types.string,
  description: Types.string,
  showUpdateButton: Types.bool,
};

export default withTranslation()(withStyles(styles)(OneClickErrorView));
