import React, {
  Component,
  useRef,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

import load from "little-loader";
import Guard from "@identity/guard-js/src/Guard";
import { withTranslation, Trans } from "react-i18next";

class ReCaptcha extends Component {
  render() {
    return (
      <div className="terms-text">
        <Trans i18nKey="common:recaptcha_terms_statement">
          This page is protected by reCAPTCHA and is subject to the Google
          <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>
          and
          <a href="https://www.google.com/policies/terms/">Terms of Service</a>.
        </Trans>
      </div>
    );
  }
}

var recaptchaV2Token = "";
var recaptchaWidgetId = null;

const ReCaptchaV2 = forwardRef(({ callback }, ref) => {
  const recaptchaRef = useRef(null);
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const onRecaptchaLoad = useCallback(() => {
    setIsRecaptchaLoaded(true);
  }, []);

  useEffect(() => {
    window.onRecaptchaLoad = onRecaptchaLoad;
    if (window.grecaptcha && window.grecaptcha.render) {
      onRecaptchaLoad();
    }

    return () => {
      window.onRecaptchaLoad = null;
    };
  }, [onRecaptchaLoad]);

  useEffect(() => {
    if (isRecaptchaLoaded) {
      if (recaptchaWidgetId !== null) {
        // Reset the state to default if already rendered
        window.grecaptcha.reset(recaptchaWidgetId);
        recaptchaV2Token = "";
        recaptchaWidgetId = null;
      }
      recaptchaWidgetId = window.grecaptcha.render(recaptchaRef.current, {
        sitekey: process.env.REACT_APP_RECAPTCHA_SITE_API_V2,
        callback: (token) => {
          recaptchaV2Token = token;
          callback(token);
        },
      });
    }
  }, [isRecaptchaLoaded, callback]);

  useImperativeHandle(ref, () => ({
    resetRecaptcha: () => {
      if (recaptchaWidgetId !== null) {
        window.grecaptcha.reset(recaptchaWidgetId);
        recaptchaV2Token = "";
      }
    },
  }));

  return <div ref={recaptchaRef}></div>;
});

function loadScript(src) {
  return new Promise((resolve, reject) => {
    load(src, (err) => {
      if (!err) {
        resolve();
      } else {
        reject(err);
      }
    });
  });
}

let loadPromise = null;
function loadReCaptcha() {
  if (!loadPromise) {
    loadPromise = loadScript(
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_API_V3}`
    ).catch(() => {
      loadPromise = null;
    });
  }
  return loadPromise;
}

function loadReCaptchaForUnitTests() {
  loadReCaptcha();
  loadPromise = Promise.resolve();
  return loadPromise;
}

async function fetchReCaptchaToken(action) {
  await loadReCaptcha();
  return new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_API_V3, { action })
        .then(resolve);
    });
  });
}

async function fetchGuardToken(action, recaptchaVersion) {
  if (recaptchaVersion === 2) {
    return `recaptchaV2:${process.env.REACT_APP_RECAPTCHA_SITE_API_V2}:${recaptchaV2Token}`;
  } else {
    await loadReCaptcha();
    return new Guard({
      recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_API_V3,
      evalPath: "/webworker/eval.js",
      powEndpoint: `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/guard/pow`,
    }).execute(action);
  }
}

const i18nReCaptcha = withTranslation(undefined, { withRef: true })(ReCaptcha);
const i18nReCaptchaV2 = withTranslation(undefined, { withRef: true })(
  ReCaptchaV2
);

export {
  i18nReCaptcha as ReCaptcha,
  ReCaptcha as ReCaptchaUnwrapped,
  loadReCaptcha,
  loadReCaptchaForUnitTests,
  fetchReCaptchaToken,
  fetchGuardToken,
  i18nReCaptchaV2 as ReCaptchaV2,
  ReCaptchaV2 as ReCaptchaV2Unwrapped,
};
