import React, { Component } from "react";
import Types from "prop-types";
import BlockedUserImg from "../../images/blocked_user.svg";
import BlockedPaymentImg from "../../images/blocked_payment.svg";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Analytika from "../../analytika";
import LanguageSelector from "../LanguageSelector";
import { ANALYTIKA_EVENTS, BLOCKED_LINK } from "../../utils/constants";
import eventAnalytics from "../../analytikaV2";

class BlockedUser extends Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.BLOCKED_USER);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.BLOCKED_USER,
      }
    );
    Analytika.fireEvent("Idtn_Web_BlockedUser_View", {
      phonenumber: props.fullPhoneNumber,
      language: props.i18n.language,
    });
  }

  getImageAndAlt(blockedErrorState) {
    if (blockedErrorState === "USER_BLOCKED") {
      return { img: BlockedUserImg, Alt: "Blocked User Image", width: 260 };
    } else if (blockedErrorState === "USER_BLOCKED_FOR_SIGNUP") {
      return {
        img: BlockedPaymentImg,
        Alt: "Blocked Payment Image",
        width: 190,
      };
    }
    return { img: BlockedUserImg, Alt: "Blocked User Image", width: 260 };
  }
  // INFO: function not being used based on current implimnetation but can be used in future
  // getErrorText(blockedErrorState) {
  //   if (blockedErrorState === "USER_BLOCKED") {
  //     return this.props.t("blocked:Your_account_is_blocked");
  //   } else if (blockedErrorState === "USER_BLOCKED_FOR_SIGNUP") {
  //     return this.props.t("blocked:under_payment_blocked_msg");
  //   }
  //   return this.props.t("blocked:Your_account_is_blocked");
  // }

  render() {
    const {
      t,
      blockedErrorState,
      redirectToLoginHandler,
      blockedErrorMsg,
      fullPhoneNumber,
      i18n,
    } = this.props;
    return (
      <div>
        <Helmet>
          <title>Blocked User | Careem</title>
        </Helmet>
        <div className="help-lang-container">
          <LanguageSelector />
        </div>
        <div className="blocked-container">
          <img
            src={this.getImageAndAlt(blockedErrorState).img}
            alt={this.getImageAndAlt(blockedErrorState).Alt}
            width={this.getImageAndAlt(blockedErrorState).width}
          />
          <h1>
            {t(
              "blocked:" +
                blockedErrorMsg
                  ?.toLowerCase()
                  .replaceAll(/\s+/g, "_")
                  .replaceAll(/\W+/g, ""),
              t("your_account_is_blocked")
            )}
          </h1>
          <span>{t("blocked:contact_care_msg")}</span>
          <input
            className="btn-careem-primary"
            type="button"
            value={t("blocked:contact_us")}
            name="blocked-contact-us"
            onClick={(e) => {
              e.preventDefault();
              eventAnalytics.publish(
                ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
                ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
                { button_name: "contact_us" }
              );
              Analytika.fireEvent("Idtn_Web_BlockedUser_ContactUs_Clicked", {
                phonenumber: fullPhoneNumber,
                language: i18n.language,
              });
              window.open(BLOCKED_LINK, "blank");
            }}
          />
          <input
            className="btn-careem-tertiary"
            type="button"
            value={t("blocked:redirect_to_login")}
            name="blocked-back-to-us"
            onClick={(e) => {
              eventAnalytics.publish(
                ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
                ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
                { button_name: "redirect_to_login" }
              );
              Analytika.fireEvent(
                "Idtn_Web_BlockedUser_RedirectToLogin_Clicked",
                {
                  phonenumber: fullPhoneNumber,
                  language: i18n.language,
                }
              );
              redirectToLoginHandler(e);
            }}
          />
        </div>
      </div>
    );
  }
}

BlockedUser.propTypes = {
  t: Types.func.isRequired,
  blockedErrorState: Types.string,
  redirectToLoginHandler: Types.func.isRequired,
  blockedErrorMsg: Types.string.isRequired,
  fullPhoneNumber: Types.string,
  i18n: Types.object.isRequired,
};

export default withTranslation()(BlockedUser);
