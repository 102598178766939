import axios from "axios";
import { initDeviceId } from "../device";
import { v4 as uuidv4 } from "uuid";
import { ARGUS_APM } from "../../apm";

export async function createSession(payload) {
  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/v8/partialSignup`;
  const { clientId, ...data } = payload;
  let deviceId = await initDeviceId().catch((ex) => {
    ARGUS_APM.captureError(ex);
    console.log(ex);
  });
  if (!deviceId) {
    deviceId = uuidv4();
  }
  return axios(endpoint, {
    method: "POST",
    headers: {
      Authorization: process.env.REACT_APP_SIGNUP_AUTH,
      "Provider-Access-Key": process.env.REACT_APP_SIGNUP_PROVIDER_ACCESS_KEY,
      agent: "Web",
      device: deviceId,
      "x-idp-client-id": clientId,
    },
    data,
  }).then((res) => res?.data);
}

export async function submitSession(sessionId, payload) {
  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/v8/partialSignup/${sessionId}/submit`;
  const {clientId, ...rest} = payload;
  const device = await initDeviceId();
  return axios(endpoint, {
    method: "POST",
    headers: {
      Authorization: process.env.REACT_APP_SIGNUP_AUTH,
      "Provider-Access-Key": process.env.REACT_APP_SIGNUP_PROVIDER_ACCESS_KEY,
      agent: "Web",
      device: device,
      "x-careem-device-id": device,
      "x-idp-client-id": clientId,
    },
    data: rest,
  }).then((res) => res?.data);
}

export async function signupWithOnboarder(payload) {

  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_ONBOARDER_HOST}/onboard`;

  const {clientId, ...rest} = payload;
  let deviceId = await initDeviceId().catch((ex) => {
    ARGUS_APM.captureError(ex);
    console.log(ex);
  });
  if (!deviceId) {
    deviceId = uuidv4();
  }
  return axios(endpoint, {
    method: "POST",
    headers: {
      "agent": "WEB",
      "version": "1",
      "x-careem-agent": "WEB",
      "x-careem-client-version": "1",
      "x-careem-client-accept-language": "en",
      "x-careem-device-id": deviceId,
      "x-idp-client-id": clientId
    },
    data: rest,
  }).then((res) => res?.data);
}
