import React, { Component } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { initDeviceId } from "../device";
import { ARGUS_APM } from "../../apm";
import { v4 as uuidv4 } from "uuid";
import { loginWithGoogle } from "../gateway/LoginGateway";
import Types from "prop-types";
import { bugsnagInstance } from "../../bugsnag";

const NO_RESPONSE = "no response from Google";
const UNREGISTERED_USER = "unregistered_user";

class GoogleButton extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.responseMessage = this.responseMessage.bind(this);
    this.tryToSignin = this.tryToSignin.bind(this);
  }

  responseMessage(response) {
    const googleIdToken = response.credential;
    this.tryToSignin(googleIdToken);
  }

  async tryToSignin(googleIdToken) {
    let deviceId = await initDeviceId().catch((ex) => {
      bugsnagInstance.notify(ex);
      ARGUS_APM.captureError(ex);
      console.log(ex);
    });
    if (!deviceId) {
      deviceId = uuidv4;
    }
    return this.loginGoogleRequest(deviceId, googleIdToken)
      .then((data) => {
        this.props.onChange({ proofToken: data.token }); // login IdP
      })
      .catch((error) => {
        bugsnagInstance.notify(error);
        ARGUS_APM.captureError(error);
        if (
          error.response?.status === 400 &&
          error.response.data?.error === UNREGISTERED_USER
        ) {
          this.props.onChange({
            googleIdToken,
          }); // signup
        } else {
          this.props.onChange({
            error: error.response?.data?.message || NO_RESPONSE,
          });
        }
      });
  }

  loginGoogleRequest(deviceId, googleIdToken) {
    return loginWithGoogle(
      {
        device_id: deviceId,
        id_token: googleIdToken,
      },
      this.state.clientId
    );
  }

  errorMessage(error) {
    bugsnagInstance.notify(error);
    console.log(error);
  }

  render() {
    // eslint-disable-next-line no-alert 
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <React.StrictMode>
          <div className="signin-google-button" data-color="black" data-border="true">
            <GoogleLogin
              onSuccess={this.responseMessage}
              onError={this.errorMessage}
            />
          </div>
        </React.StrictMode>
      </GoogleOAuthProvider>
    );
  }
}

GoogleButton.propTypes = {
  onChange: Types.func,
};
export default GoogleButton;
