import React from "react";
import GiftIcon from "../../../images/giftIcon.svg";
import DiscountIcon from "../../../images/discountIcon.svg";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../forms/styles";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";

function InlinePromoLine(props) {
  const { t, promoValue, promoType, promoOutcome, classes} = props
  const  {i18n} = useTranslation();
  const dir = i18n.dir()
  return (
    <div className={classes.promoContainer}>
      { 
      promoOutcome === 'CASHBACK' ? 
      <Typography className={classes.promoText} style={{direction: `${dir}`}}>
        <Trans t={t} i18nKey="one_click_checkout:inline_promo_line_cashback">
        <img src={GiftIcon} alt="Gift Icon" style={{verticalAlign:"text-top", height:"18px"}}/> 
        <span className={classes.amount}>{{promoValue: promoValue}}{{ promoType: promoType === '%' ? '%': `${t("one_click_checkout:promoType")}`}}</span>
        </Trans>
      </Typography>
      :
      <Typography className={classes.promoText} style={{direction: `${dir}`}}>
        <Trans t={t} i18nKey="one_click_checkout:inline_promo_line_discount">
        <img src={DiscountIcon} alt="Discount Icon" style={{verticalAlign:"text-top", height:"18px"}}/>
        <span className={classes.amount}>{{promoValue: promoValue}}{{ promoType: promoType === '%' ? '%': `${t("one_click_checkout:promoType")}`}}</span>
        </Trans>
      </Typography>
      }
    </div>
  );
}


InlinePromoLine.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
  promoValue: PropTypes.string,
  promoType: PropTypes.string,
  promoOutcome: PropTypes.string,
};

export default withTranslation() (withStyles(styles)(InlinePromoLine));
