import axios from "axios";
import Request from "axios-request-handler";
import { initDeviceId, initProfiling } from "../device";
import { v4 as uuidv4 } from "uuid";
import { ARGUS_APM } from "../../apm";

export async function loginWithPhone(payload, clientId) {
  const endpoint = getEndpoint(`/login/customer/phone`);

  return login(endpoint, clientId, payload, true, true);
}
export async function loginWithOneClick(payload, clientId) {
  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/login/one-click`;
  return login(endpoint, clientId, payload);
}

export function verifyApproveRequestStatus(id) {
  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/login/approve-request/${id}/verify`;
  return new Request(endpoint);
}

export async function proofApproveRequest(id, clientId) {
  let profiling = await initProfiling().catch((ex) => {
    console.log(ex);
  });
  const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/login/approve-request/${id}/proof`;
  return axios
    .post(
      endpoint,
      { profiling },
      {
        headers: {
          "x-idp-client-id": clientId,
        },
      }
    )
    .then((res) => res?.data);
}

export async function loginWithApple(payload, clientId) {
  const endpoint = getEndpoint(`/login/apple`);

  return login(endpoint, clientId, payload, true, true);
}

export async function loginWithGoogle(payload, clientId) {
  const endpoint = getEndpoint(`/login/google`);

  return login(endpoint, clientId, payload, true, true);
}

export async function loginWithEmail(payload, clientId) {
  const endpoint = getEndpoint(`/login/customer/email`);

  return login(endpoint, clientId, payload, true, true);
}

async function login(
  endpoint,
  clientId,
  payload,
  withDeviceIdHeader = true,
  withOnboarderHeaders = false
) {
  payload.device_id = await initDeviceId().catch((ex) => {
    console.log(ex);
    let e = Error("deviceId is empty. " + ex?.message);
    e.causedBy = ex;
    if (ARGUS_APM) {
      ARGUS_APM.captureError(e);
    }
  });
  if (!(payload.device_id?.length > 0)) {
    const ex = Error("deviceId is empty. fallback to uuid");
    if (ARGUS_APM) {
      ARGUS_APM.captureError(ex);
    }
    payload.device_id = uuidv4();
  }
  let headers = { "x-idp-client-id": clientId };
  if (withDeviceIdHeader) {
    headers["x-careem-device-id"] = payload.device_id;
  }
  if (withOnboarderHeaders) {
    headers["agent"] = "WEB";
    headers["version"] = "1";
  }

  return axios
    .post(endpoint, payload, {
      headers,
    })
    .then((res) => res?.data);
}

function getEndpoint(pathOnboarder) {
  return `${process.env.REACT_APP_IDENTITY_SERVICES_ONBOARDER_HOST}`.concat(
    pathOnboarder
  );
}
