import React from "react";
import axios from "axios";
import CareemLogo from "../../images/transparent_logo.png";
import LoadingSpinner from "../LoadingSpinner";
import VerificationLinkExpired from "./VerificationLinkExpired";
import VerificationTokenAlreadyUsed from "./VerificationTokenAlreadyUsed";
import EmailVerified from "./EmailVerified";
import UnlinkEmail from "./UnlinkEmail";
import i18n from "i18next";

export default class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    this.state = {
      loading: true,
      linkVerified: false,
      linkExpired: false,
      unlinkEmail: false,
      linkAlreadyUsed: false,
      error: false,
      unlink: props.unlink,
      verify: props.verify,
      language: params.get("lang") || "en",
    };
    i18n.changeLanguage(this.state.language);
  }

  componentDidMount() {
    document.title = "Email verification | Careem";
    if (this.state.unlink) {
      this.unlink();
    } else if (this.state.verify) {
      this.verify();
    } else {
      this.setState({ linkExpired: true, loading: false });
    }
  }

  unlink() {
    axios
      .delete(
        `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/users/email/verification/${this.props.match.params.verificationId}`
      )
      .then((res) => {
        if (200 === res.status) {
          this.setState({ linkAlreadyUsed: true, loading: false });
        } else {
          this.setState({ unlinkAccount: true, loading: false });
        }
      })
      .catch((failure) => {
        this.setState({ linkExpired: true, loading: false });
      });
  }

  verify() {
    axios
      .put(
        `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/users/email/verification/${this.props.match.params.verificationId}`
      )
      .then((res) => {
        if (200 === res.status) {
          this.setState({ linkAlreadyUsed: true, loading: false });
        } else {
          this.setState({ linkVerified: true, loading: false });
        }
      })
      .catch((failure) => {
        this.setState({ linkExpired: true, loading: false });
      });
  }

  render() {
    let component;
    if (this.state.loading) {
      component = <LoadingSpinner />;
    } else if (this.state.linkVerified) {
      component = <EmailVerified />;
    } else if (this.state.linkExpired) {
      component = <VerificationLinkExpired />;
    } else if (this.state.linkAlreadyUsed) {
      component = <VerificationTokenAlreadyUsed />;
    } else if (this.state.unlinkAccount) {
      component = <UnlinkEmail />;
    }
    return <EmailVerificationContainer children={component} />;
  }
}

export const EmailVerificationContainer = (props) => {
  return (
    <div className="email_container">
      <div className="careem-logo-container">
        <img src={CareemLogo} className="careem-logo" alt="Careem Logo" />
      </div>
      {props.children}
    </div>
  );
};
