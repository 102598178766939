import React from "react";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

class PasswordChanged extends React.Component {
  componentDidMount() {
    document.title = "Password updated | Careem";
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_IDENTITY_REDIRECT;
    }, 2000);
  }

  render() {
    return (
      <div>
        <h1>
          You password is changed successfully{" "}
          <span role="img" aria-label="Tada">
            🎉
          </span>
        </h1>
        <h3>
          You will be redirected to{" "}
          <a href={process.env.REACT_APP_IDENTITY_REDIRECT}>login</a>
        </h3>
      </div>
    );
  }
}

export default withRouter(withTranslation()(PasswordChanged));
