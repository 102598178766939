const styles = () => ({
  sendAgainContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 0px",
    minHeight: "55px",
  },
  sendAgainButtonText: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#889db3",
    textTransform: "capitalize",
  },
  sendAgainButton: {
    position:'relative',
    appearance:'none',
    color: "var(--text-state-success)",
    padding: "10px 20px",
    boxShadow: "none",
    transition: "all 300ms",
    "&:hover": {
      color:"var(--components-link-success-text-pressed)",
      background:'transparent',
    },
  },
  sendAgainButtonDisabled: {
    border: "0px solid #f0f2f4 !important",
    padding: "6px 40px",
  },
  sendAgainButtonIcon: {
    position:'absolute',
    transform:'translateY(-50%)',
    right:'5px',
    fontSize: "16px !important",
    backgroundColor: "#ebedf1",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  openCareemAppButton: {
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
    textTransform: "capitalize",
  },
  appStoreImg: {
    objectFit: "cover",
  },
  title: {
    margin: "1.5rem 0rem",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#2D2E2E",
    "@media only screen and (max-width: 480px)": {
      fontSize: "20px",
    },
  },
  formSubtitle: {
    fontSize: "16px",
    color: "#5D758F",
    margin: "4px 0px",
    "& > span": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  sizeControl: {
    display:'flex',
    flexDirection:"column",
    justifyContent: "center",
    height: "537px",
  },
  textButton: {
    outline: "none",
    background: "none",
    border: "none",
    padding: 0,
    color: "var(--text-state-success)",
    fontSize: "16px",
    fontWeight: 600,
  },
  notificationBox: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gridColumnGap: "4px",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  notificationText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#889DB4",
  },
  tickIcon: {
    color: "#889DB4",
    marginTop: "3px",
  },
  grey500: {
    color: "#5D758F",
  },
  textSuccess: {
    color: "#18AA33",
  },
  textSmall: {
    fontSize: "14px",
  },
  promoContainer:{
    display:'flex',
    margin:'auto',
    height: '40px',
    width: 'fit-content',
    borderRadius:'20px',
    gap: '4px',
    padding: '8px 12px',
    background:'#F7FAFC',
    color:'#00493E'
  },
  promoText: {
    display:'flex-inline',
    whiteSpace: 'pre-wrap',
    fontSize:'16px',
    unicodeBidi: "embed",
    '@media (max-width: 767px)': {
      fontSize: '14px' 
    },
    '@media (max-width: 480px)': {
      fontSize: '12px' 
    }
  },
  offerIcon:{
    height:'18px',
    margin:'2px 0',
    '@media (max-width: 767px)': {
      height: '14px' 
    },
    '@media (max-width: 480px)': {
      height: '12px' 
    }
  },
  amount:{
    fontWeight:700,
    color:'#00493E',
  }
});

export default styles;
