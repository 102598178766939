import React from "react";
import Types from "prop-types";
import axios from "axios";
import queryString from "query-string";

import LoadingSpinner from "../LoadingSpinner";
import ChallengeFullName from "./ChallengeFullName";

export const FULL_NAME_CHALLENGE = "FULL_NAME_CHALLENGE";

export class ChallengeLoader extends React.Component {
  constructor(props) {
    super(props);
    const { clientId, otp, phoneNumber, verificationId } = queryString.parse(
      props.location.search
    );
    this.state = {
      challenge: null,
      loading: true,
      session: { clientId, phoneNumber, otp, verificationId },
    };
  }

  componentDidMount() {
    document.title = "Recovery | Careem";
    const { location } = this.props;
    const { clientId, otp, verificationId, phoneNumber } = queryString.parse(
      location.search
    );
    if (!phoneNumber || !clientId || (!otp && !verificationId)) {
      this.props.history.replace("/session_unknown");
    } else {
      this.fetchChallenges({ phoneNumber, clientId, otp, verificationId });
    }
  }

  fetchChallenges(session) {
    const { phoneNumber, clientId, otp, verificationId } = session;
    const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/recovery/challenges/${clientId}`;
    return axios
      .get(endpoint, { params: { phoneNumber, otp, verificationId } })
      .then((res) => res.data)
      .then((res) => {
        let challenge = res.challenges[0];
        this.setState({ challenge, loading: false });
      })
      .catch(() => this.props.history.push("/session_unknown"));
  }

  verifyChallenge(type, { recaptcha, answer }) {
    const { clientId, otp, verificationId, phoneNumber } = this.state.session;
    const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/recovery/notification/${clientId}`;

    return axios
      .post(endpoint, {
        phoneNumber,
        otp,
        verificationId,
        challenges: [{ challengeIdentifier: type, challengeAnswer: answer }],
      })
      .then((res) => res.data);
  }

  render() {
    const { challenge, loading } = this.state;

    if (loading) return <LoadingSpinner />;

    switch (challenge.challengeIdentifier) {
      case FULL_NAME_CHALLENGE:
        return (
          <ChallengeFullName
            challenge={challenge}
            verifyChallenge={this.verifyChallenge.bind(this,FULL_NAME_CHALLENGE)}
            phoneNumber={this.state.phoneNumber}
          />
        );
      default:
        return "Recovery password challenges are not configured for this client";
    }
  }
}
ChallengeLoader.propTypes = {
  location: Types.object.isRequired,
  history: Types.object.isRequired,
};

export default ChallengeLoader;
