import React from "react";

export const CorporateContainer = (props) => {
  return (
    <div style={{ paddingBottom: "50px", backgroundColor: "#F2F5F8" }}>
      <div
        style={{
          width: "90%",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export const CorporateLogo = () => {
  return (
    <div style={{ width: "100%", height: "65px" }}>
      <img
        style={{ margin: "20.5px 0" }}
        alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/careem_business_logo.png"
      />
    </div>
  );
};

export const CorporateFooter = () => {
  return (
    <div>
      <div style={{ marginTop: "60px" }}>
        <div style={{ width: "300px", margin: "0 auto" }}>
          <a
            href="https://apps.apple.com/us/app/careem-%D9%83%D8%B1%D9%8A%D9%85-car-booking-app/id592978487"
            target="_blank"
            style={{ float: "left" }} rel="noopener noreferrer"
          >
            <img alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/App_Store.png" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.careem.acma"
            target="_blank"
            style={{ float: "right" }} rel="noopener noreferrer"
          >
            <img alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/google_play.png" />
          </a>
          <div style={{ clear: "both" }} />
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <p style={{ textAlign: "center" }}>
          <small>
            Careem Networks FZ-LLC
            <br />
            Shatha Tower Dubai Media City, Dubai, UAE
            <br />
            Tax Registration Number: 100345787400003
          </small>
        </p>
      </div>
      <div style={{ marginTop: "30px" }}>
        <div style={{ margin: "0 auto", width: "102px" }}>
          <a
            href="https://www.facebook.com/careemUAE"
            target="_blank"
            style={{
              margin: "0 5px",
              display: "block",
              float: "left",
            }} rel="noopener noreferrer"
          >
            <img alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/Social_Fb.png" />
          </a>
          <a
            href="https://www.twitter.com/careemUAE"
            target="_blank"
            style={{
              margin: "0 5px",
              display: "block",
              float: "left",
            }} rel="noopener noreferrer"
          >
            <img alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/Social_twitter.png" />
          </a>
          <a
            href="https://www.instagram.com/careemUAE/"
            target="_blank"
            style={{
              margin: "0 5px",
              display: "block",
              float: "left",
            }} rel="noopener noreferrer"
          >
            <img alt="" src="https://c4b.s3-eu-west-1.amazonaws.com/Emails/Social_Insta.png" />
          </a>
          <div style={{ clear: "both" }} />
        </div>
        <small
          style={{
            marginTop: "10px",
            textAlign: "center",
            display: "block",
          }}
        >
          Copyright © 2020 Careem
        </small>
      </div>
    </div>
  );
};
