import React, { Fragment } from "react";
import "./ClientManagement.css";

export default class ClientManagement extends React.Component {
  render() {
    const { name, logoUri, removeAssociationCallback } = this.props;

    return (
      <Fragment>
        <img src={logoUri} alt=""/>
        <div>{name}</div>
        <button onClick={removeAssociationCallback}>Remove</button>
      </Fragment>
    );
  }
}
