import React from "react";
import ReactDOM from "react-dom";
import Root from "./routes";
// import i18n (needs to be bundled ;))
import "./i18n";
import "../node_modules/@careem-public/aurora-tokens/styles/css/_theme.css";
import "./styles/styles.css";
import "./styles/aurora/aurora-styles.css";
import "./styles/express/express-styles.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { loadReCaptcha } from "./components/Guard";
import { ARGUS_APM } from "./apm";
import { bugsnagInstance } from "./bugsnag";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BugsnagErrorBoundary = bugsnagInstance.getPlugin("react").createErrorBoundary(React);
loadReCaptcha();
ARGUS_APM.setInitialPageLoadName("home");
ReactDOM.render(
  <BugsnagErrorBoundary>
    <Root />
  </BugsnagErrorBoundary>,
  document.getElementById("app-root")
);
