import React from "react";
import careem_wink_green from "../../images/careem_wink_green.svg";
import { withTranslation } from "react-i18next";

class CorporateSignupError extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <img src={careem_wink_green} alt="Careem Logo" className="signed-in-logo" />
        <h1> {t("signup:corporate_error_signup")}</h1>

      </div>
    );
  }
}
export default withTranslation()(CorporateSignupError);
