import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import React from "react";
import { getMobileOperatingSystem } from "../common/CommonMethods";
import {
  APP_STORE_URL,
  CAREEM_APP_URL,
  PLAY_STORE_URL,
} from "../../utils/constants";
import eventAnalytics from "../../analytikaV2";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */

const GotoCareemAppButton = () => {
  const customTheme = createMuiTheme({
    palette: {
      primary: { main: "#3AB54E" },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    shape: {
      borderRadius: 40,
    },
    overrides: {
      MuiButton: {
        label: {
          color: "#ffffff",
          fontSize: "large",
          fontWeight: "bold",
        },
      },
    },
  });

  var deepLinkTimeout;
  const openDeepLink = () => {
    window.location.href = CAREEM_APP_URL;
  };

  const redirectToAppStore = (mobileOs) => {
    if (mobileOs === "iOS") {
      window.location.href = APP_STORE_URL;
    } else {
      window.location.href = PLAY_STORE_URL;
    }
  };

  window.addEventListener("blur", () => {
    // If the user switches to another tab or app, clear the timeout
    clearTimeout(deepLinkTimeout);
  });

  window.addEventListener("focus", () => {
    // If the user comes back to the tab, remove the event listener
    clearTimeout(deepLinkTimeout);
  });

  window.addEventListener("visibilitychange", () => {
    // If the tab visibility changes, clear the timeout
    clearTimeout(deepLinkTimeout);
  });

  const openApp = () => {
    eventAnalytics.publish("pyck_tap_ck_button", null, {
      flow: "identity",
      button_name: "open_careem_app",
      screen_name: "await_verification",
    });

    window.location.href = CAREEM_APP_URL;

    // Attempt to open the deep link
    deepLinkTimeout = setTimeout(() => {
      // If the deep link doesn't work, redirect to the app store based on OS
      const mobileOs = getMobileOperatingSystem();
      redirectToAppStore(mobileOs);
    }, 1000); // Set a timeout to handle cases where the deep link takes too long to open

    openDeepLink();
  };
  return (
    <p className="open-careem-button-container">
      <ThemeProvider theme={customTheme}>
        <Button
          variant="contained"
          color="primary"
          style={{ minWidth: "220px" }}
          onClick={openApp}
        >
          Open Careem
        </Button>
      </ThemeProvider>
    </p>
  );
};

export default GotoCareemAppButton;
