import { createContext } from "react";
import axios from "axios";
import queryString from "query-string";

export const IdpClientContext = createContext('');

class IdpClientService {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_CLIENTS_SERVICE_BASE_URL,
    });
  }
  getClientId() {
    try {
      const { location: { search } } = window;
      const { clientId } = queryString.parse(search);
      return clientId;
    } catch (err) {
      return null;
    }
  }
  fetchIdpClientData(clientId) {
    return this.client.get(`clients/api/v3/clients/${clientId}`);
  }
}
export default new IdpClientService();
