import React from "react";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";
import {
  CorporateContainer,
  CorporateLogo,
  CorporateFooter,
} from "./CorporateTemplate";

export default class CorporateUserEnrolment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidToken: false,
      loading: true,
    };
  }

  componentDidMount() {
    document.title = "Corporate | Careem";
    const token = this.props.match.params.token;
    this.verifyCorporateUserInvitation(token);
  }

  verifyCorporateUserInvitation(token) {
    const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/corporate/user/invitation/${token}`;

    axios
      .post(endpoint, {})
      .then((res) => res.status)
      .then((status) => {
        this.setState({ isValidToken: true, loading: false });
      })
      .catch(() => {
        this.setState({ isValidToken: false, loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    if (this.state.isValidToken) {
      return (
        <CorporateContainer>
          <CorporateLogo />
          <div style={{ padding: "30px 20px", backgroundColor: "#FFFFFF" }}>
            <p style={{ textAlign: "center" }}>
              <strong>Welcome to careem business</strong>
            </p>
            <p>
              <strong>
                Here are some of the benefits of being a Careem Corporate user:
              </strong>
            </p>
            <p>
              - Our Corporate Care team is available 24/7, contact them by
              phone, email or through our app.
            </p>
            <p>- Get only the highest rated Captains in your area.</p>
            <p>
              - Having a Business Profile makes the expensing process effortless
              since the receipt will be automatically emailed to your company
              after every ride.
            </p>
            <p>
              You can also order a car for immediate pick-up or pre-book to get
              picked up later. For a multi-stop pickup, or to repeat a trip over
              multiple days, book through our website.
            </p>
            <p>
              For your further afield travelers, you can use Careem with the
              same app and corporate conditions in 13 other countries in the
              MENA region.
            </p>
            <p>Regards,</p>
            <p>Team Careem</p>
            <CorporateFooter />
          </div>
        </CorporateContainer>
      );
    } else {
      return (
        <CorporateContainer>
          <CorporateLogo />
          <div style={{ padding: "30px 20px", backgroundColor: "#FFFFFF" }}>
            <p style={{ textAlign: "center" }}>
              <strong>
                We couldn't verify your email. Please contact careem customer
                service.
              </strong>
            </p>
            <CorporateFooter />
          </div>
        </CorporateContainer>
      );
    }
  }
}
