import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Types from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { expressButtonStyles } from "./styles/buttonStyles";

const useStyles = makeStyles(expressButtonStyles);

const ButtonEnhanced = ({ btnType, className, children, ...restProps }) => {
  const classes = useStyles();
  let btnClass;
  switch (btnType) {
    case "danger":
      btnClass = classes.danger;
      break;
    case "primary":
      btnClass = classes.primary;
      break;
    case "link":
      btnClass = classes.link;
      break;
    case "tertiary":
    default:
      btnClass = classes.tertiary;
  }

  return (
    <Button className={clsx(classes.button, btnClass, className)} {...restProps}>
      {children}
    </Button>
  );
};

ButtonEnhanced.propTypes = {
  children: Types.node,
  btnType: Types.oneOf(["primary", "tertiary", "danger"]),
  isFullWidth: Types.bool,
  className: Types.string
};

export default ButtonEnhanced;
