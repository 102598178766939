import React from "react";
import { Helmet } from "react-helmet";
import { ReCaptcha } from "../../Guard";
import Types from "prop-types";
import GoogleButton from "../../common/GoogleButton";

export default class GoogleLoginForm extends React.Component {
  render() {
    const { error, onGoogleTokenChanged } = this.props;
    return (
      <form>
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        {error && <div className="invalid show">{error}</div>}
        <div className="center">
          <GoogleButton onChange={onGoogleTokenChanged} />
        </div>

        <ReCaptcha />
      </form>
    );
  }
}

GoogleLoginForm.propTypes = {
  onGoogleTokenChanged: Types.func.isRequired,
};
