import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Timer from "./Timer";
import LanguageSelector from "../LanguageSelector";
import { sendOtp, verifyOtp } from "../gateway/OtpGateway";
import { ARGUS_APM } from "../../apm";
import Types from "prop-types";
import { fetchGuardToken, ReCaptcha, ReCaptchaV2 } from "../Guard";
import Analytika from "../../analytika";
import { initDeviceId } from "../device";
import HelpCenterButton from "../common/HelpCenterButton";
import { ANALYTIKA_EVENTS, HELP_CENTER_LINK } from "../../utils/constants";
import eventAnalytics from "../../analytikaV2";
import { ArrowBack } from "@material-ui/icons";
import ButtonEnhanced from "../common/enhanced/ButtonEnhanced";
import { isWidthDown, withWidth, IconButton } from "@material-ui/core";
import { bugsnagInstance } from "../../bugsnag";
import Galileo from "../../galileo";
import IdpClientService from "../../idpClient";

class OtpView extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.OTP_VERIFY);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.OTP_VERIFY,
      }
    );
    Analytika.fireEvent("Idtn_Web_OtpScreen_View", {
      phoneNumber: this.props.identifier,
    });
    eventAnalytics.publish("pyck_response_ck_validation", null, {
      screen_name: "enter_phone",
      flow: "identity",
    });
    this.state = {
      showResendTimer: true,
      error: null,
      otp: "",
      submitting: false,
      isRecaptchaV2Visible: false,
      recaptchaV2Token: "",
      isRecaptchaV2Enabled: false,
    };
    this.textInput = React.createRef();
    this.resendOtp(this.isEmail() ? "EMAIL" : "SMS");
    this.recaptchaRef = React.createRef();
  }
  
  componentDidMount() {
    const clientId = IdpClientService.getClientId();
    Galileo.fetchIsGoogleRecaptchaV2Enabled(clientId)
      .then((_isRecaptchaV2Enabled) => {
        this.setState({
          isRecaptchaV2Enabled: _isRecaptchaV2Enabled,
        });
      })
      .catch((err) => {
        ARGUS_APM.captureError(err);
        this.setState({
          galileoVars: { isLoading: false },
        });
      });
  }

  isEmail() {
    return this.props.identifier.indexOf("@") > 0;
  }
  recaptchaV2(token) {
    this.setState({ isRecaptchaV2Visible: false, recaptchaV2Token: token });
    this.resendOtp("SMS", 2);
  }
  resetRecaptcha() {
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.resetRecaptcha();
    }
  }
  async resendOtp(type, recaptchaVersion = 3) {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
      {
        request_type: "otp_request",
      }
    );
    if (type === "SMS") {
      Analytika.fireEvent("Idtn_Web_Otp_Resend_Sms_Click", {
        phoneNumber: this.props.identifier,
      });
    } else if (type === "VOICE") {
      Analytika.fireEvent("Idtn_Web_Otp_Resend_Call_Click", {
        phoneNumber: this.props.identifier,
      });
    } else if (type === "EMAIL") {
      Analytika.fireEvent("Idtn_Web_Otp_Resend_Email_Click", {
        phoneNumber: this.props.identifier,
      });
    }
    try {
      await sendOtp({
        type,
        guard: await fetchGuardToken(null, recaptchaVersion),
        identifier: this.props.identifier,
        clientId: this.props.clientId,
        deviceId: await initDeviceId(),
      });
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "otp_request",
          request_status: "success",
        }
      );
      Analytika.fireEvent("Idtn_Web_Otp_Generate_Success", {
        phoneNumber: this.props.identifier,
      });
      this.setState({ showResendTimer: true });
    } catch (e) {
      bugsnagInstance.notify(e);
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "otp_request",
          request_status: "fail",
          error_code_string: "",
          error_description: e,
        }
      );
      Analytika.fireEvent("Idtn_Web_Otp_Generate_Fail", {
        phoneNumber: this.props.identifier,
      });
      this.onRequestError(e);
    }
  }

  async onSubmit(event) {
    console.log("SUBMIT_CLICK");
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
      {
        button_name: "continue",
        otp_entered: this.state.otp,
      }
    );
    event.preventDefault();

    this.setState({
      submitting: true,
      error: null,
    });

    try {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "otp_submit",
        }
      );
      let { data } = await verifyOtp({
        identifier: this.props.identifier,
        code: this.state.otp,
        clientId: this.props.clientId,
        deviceId: await initDeviceId(),
      });
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "otp_submit",
          request_status: "success",
        }
      );
      Analytika.fireEvent("Idtn_Web_Otp_Verify_Success", {
        phoneNumber: this.props.identifier,
      });
      this.props.onOtpVerify(data.id);
    } catch (e) {
      bugsnagInstance.notify(e);
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "otp_submit",
          request_status: "fail",
          error_code_string: "",
          error_description: e,
        }
      );
      Analytika.fireEvent("Idtn_Web_Otp_Verify_Fail", {
        phoneNumber: this.props.identifier,
      });
      this.onRequestError(e);
    } finally {
      this.setState({ submitting: false });
    }
  }

  renderResendButton(type, caption) {
    const { showResendTimer } = this.state;

    let onClick = (e) => {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_name,
        ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_version,
        {
          page_name: "otp_verify",
          resent_otp: type,
        }
      );
      if (this.state.isRecaptchaV2Enabled) {
        this.resetRecaptcha();
        this.setState({
          otp: "",
          error: null,
          isRecaptchaV2Visible: true,
          recaptchaV2Token: "",
        });
      } else {
        this.resendOtp(type);
        this.setState({
          otp: "",
          error: null,
        });
      }
      this.textInput.focus();
      e.preventDefault();
    };

    return (
      <input
        type="reset"
        name={type}
        className="resend-btn"
        onClick={onClick}
        disabled={showResendTimer}
        value={caption}
      />
    );
  }

  onRequestError(e) {
    bugsnagInstance.notify(e);
    ARGUS_APM.captureError(e);
    if (e.response?.data) {
      this.setState({ error: e.response.data });
    } else {
      this.setState({ error: { message: "ERROR_UNKNOWN" } });
    }
  }

  onFinishTimer() {
    this.setState(() => ({
      showResendTimer: false,
    }));
  }

  renderClassic() {
    const { t, submitError, identifier } = this.props;
    const { error, showResendTimer, submitting } = this.state;
    let timer = showResendTimer && (
      <Timer onFinish={this.onFinishTimer.bind(this)} timeInSecs={60} />
    );
    return (
      <form onSubmit={this.onSubmit.bind(this)} autoComplete="off">
        <div className="help-lang-container">
          <HelpCenterButton href={HELP_CENTER_LINK.CODE_VERIFICATION} />
          <LanguageSelector />
        </div>
        <h1>{t("otp:verification_field")}</h1>
        <h3>
          {t("otp:check_otp")} {identifier}
        </h3>

        {!submitError && error && (
          <div className="invalid show">
            {t(["otp:error." + error.message, "error:default"])}
          </div>
        )}
        {submitError && <div className="invalid show">{submitError}</div>}
        <div className="material-form-field ">
          <input
            type="text"
            autoComplete="off"
            spellCheck="false"
            name="otp_code"
            value={this.state.otp}
            onChange={(event) => {
              const value = event.target.value;
              this.setState({ otp: value });
              if (value.length === 1) {
                eventAnalytics.publish(
                  ANALYTIKA_EVENTS.IDNW_ENTER_OTP.event_name,
                  ANALYTIKA_EVENTS.IDNW_ENTER_OTP.event_version,
                  {
                    otp_entered: value,
                  }
                );
              }
              this.setState({ otp: event.target.value });
            }}
            onFocus={() =>
              Analytika.fireEvent("Idtn_Web_Otp_Code_Focused", {
                phoneNumber: this.props.identifier,
              })
            }
            required
            autoFocus
            ref={(ref) => (this.textInput = ref)}
          />
          <label className="material-form-field-label">
            {t("otp:enter_otp")}
          </label>
        </div>
        <div className="recaptcha-v2-container">
          <h3 className="otp-resend-text">
            {t("otp:resend_otp_text")} &nbsp;
            {!this.isEmail() && (
              <span>
                {this.renderResendButton("SMS", t("otp:resend_otp_code"))}
                <span className="resend-delimiter">|</span>
                {this.renderResendButton("VOICE", t("otp:resend_otp_voice"))}
              </span>
            )}
            {this.isEmail() && (
              <span>
                {this.renderResendButton("EMAIL", t("otp:resend_otp_code"))}
              </span>
            )}
            {timer}
          </h3>
          {this.state.isRecaptchaV2Visible && (
            <>
              <ReCaptchaV2
                ref={this.recaptchaRef}
                callback={this.recaptchaV2.bind(this)}
              />
            </>
          )}
        </div>
        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          disabled={submitting}
        />
        <ReCaptcha />
      </form>
    );
  }

  renderEnhanced() {
    const { t, submitError, identifier, onClickBack, width } = this.props;
    const { error, showResendTimer, submitting } = this.state;

    const isSmallDevice = isWidthDown("xs", width);
    let timer = showResendTimer && (
      <>
        {t("common:in")}
        <Timer onFinish={this.onFinishTimer.bind(this)} timeInSecs={60} />
      </>
    );
    return (
      <form id="formOtp" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
        <div dir="ltr">
          {isSmallDevice ? (
            <IconButton
              className="express-icon-button"
              id="back-button-mobile"
              style={{ position: "absolute", top: "2.5vh" }}
              size="medium"
              onClick={onClickBack}
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <ButtonEnhanced
              id="back-button"
              style={{ position: "relative", right: "12vw", top: "6vh" }}
              startIcon={<ArrowBack />}
              btnType="link"
              onClick={onClickBack}
            >
              {t("otp:back")}
            </ButtonEnhanced>
          )}
          <h1 className="heading-h1-express center express-common">
            {t("otp:verification_field")}
          </h1>
        </div>
        <h3 className="heading-h3-express center express-common">
          {t("otp:check_otp")} {identifier}
        </h3>
        <div className="express-form-field-label show express-common">
          {t("otp:enter_otp")}
        </div>
        <div className="express-form-field express-common">
          <input
            type="text"
            autoComplete="off"
            spellCheck="false"
            name="otp_code"
            value={this.state.otp}
            onChange={(event) => this.setState({ otp: event.target.value })}
            onFocus={() =>
              Analytika.fireEvent("Idtn_Web_Otp_Code_Focused", {
                phoneNumber: this.props.identifier,
              })
            }
            required
            autoFocus
            ref={(ref) => (this.textInput = ref)}
          />
        </div>
        {!submitError && error && (
          <div className="invalid show">
            {t(["otp:error." + error.message, "error:default"])}
          </div>
        )}
        {submitError && <div className="invalid show">{submitError}</div>}
        <input
          className="btn-express btn-express-primary"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          disabled={submitting}
        />
        <div>
          <h3 className="heading-h3-express center express-common">
            {this.renderResendButton("SMS", t("otp:resend_otp_code"))} {timer}
          </h3>

          <div className="express-extra-text show express-success-screen-line">
            <a
              className="express-text-primary"
              href="https://help.careem.com/hc/en-us/articles/360001609587-What-if-I-didn-t-receive-a-verification-code"
            >
              {t("otp:resend_otp_text")}
            </a>{" "}
            <b>{this.renderResendButton("VOICE", t("otp:resend_otp_voice"))}</b>
            {this.isEmail() && (
              <span>
                {this.renderResendButton("EMAIL", t("otp:resend_otp_code"))}
              </span>
            )}
          </div>
        </div>
        <ReCaptcha />
      </form>
    );
  }

  render() {
    const { isEnhancedFormEnabled } = this.props;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }
}

OtpView.propTypes = {
  clientId: Types.string,
  identifier: Types.string.isRequired,
  onOtpVerify: Types.func,
  t: Types.func,
  submitError: Types.any,
  invoiceId: Types.string,
  isEnhancedFormEnabled: Types.bool,
  onClickBack: Types.func,
  width: Types.string,
};

export default withWidth()(withRouter(withTranslation()(OtpView)));
export const OtpViewUnwrapped = OtpView;
