import { Analytika } from "@analytika/analytika-web";
import { v4 as uuidv4 } from "uuid";

if (typeof window !== "undefined") {
  const env = process.env.REACT_APP_ANALYTIKA_ENV;
  Analytika.init(env, false, {}, 10, 10);

  if (typeof document.visibilityState !== 'undefined') {
    document.addEventListener("visibilitychange", function () {
      // fires when user switches tabs, apps, goes to homescreen, etc.
      if (document.visibilityState === "hidden") {
        Analytika.getInstance().flush();
      }
    });
  }
}

class EventAnalytics {
  constructor() {
    this.globalAttrs = {
      sessionUuid: uuidv4(),
      appName: process.env.REACT_APP_ANALYTIKA_EVENT_SOURCE,
      userAgent: navigator.userAgent,
      device_id: "",
      client_id: "",
    };
    this.invoiceId = undefined;
    this.phoneNumber = undefined;
    this.intent = undefined;
    this.page_name = undefined;
    this.flow_name = undefined;
  }

  publish(eventName, eventVersion, eventData) {
    Analytika.publish({
      event_name: eventName,
      event_version: eventVersion ?? 1,
      event_trigger_time: new Date().getTime(),
      invoice_id: this.invoiceId,
      phone_number: this.phoneNumber,
      entered_phone_number: this.phoneNumber,
      intent: this.intent,
      flow_name: this.flow_name,
      page_name: this.page_name,
      ...this.globalAttrs,
      ...eventData,
    });
  }
  flush() {
    return Analytika.getInstance().flush();
  }
  setInvoiceId(invoiceId) {
    this.invoiceId = invoiceId;
  }

  setIntent(intent) {
    this.intent = intent;
  }

  setFlowName(flow_name) {
    this.flow_name = flow_name;
  }

  setPageName(page_name) {
    this.page_name = page_name;
  }

  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  setDeviceId(device_id) {
    this.globalAttrs.device_id = device_id;
  }

  setClientId(clientId) {
    this.globalAttrs.client_id = clientId;
  }
}

const eventAnalytics = new EventAnalytics();
export default eventAnalytics;
