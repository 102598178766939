import React, { Fragment, useEffect, useRef, useState } from "react";
import Types from "prop-types";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import parsePhoneNumber from "libphonenumber-js";
import "./PhoneNumber.css";
import Analytika from "../../analytika";
import eventAnalytics from "../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../utils/constants";

export function sanitizePhone(phoneNumber) {
  return phoneNumber?.replace(/[\D]+/g, "");
}

export function maskPhoneNumber(phoneNumber) {
  let groups = phoneNumber.replace(/\D/g, "").match(/(\d{3})(\d+)(\d{2})\b/);
  return "+" + groups[1] + " " + groups[2].replace(/./g, "*") + " " + groups[3];
}

export function parsePhone(phoneNumber) {
  const number = "+" + sanitizePhone(phoneNumber);
  const parsedNumber = parsePhoneNumber(number);

  return {
    isValid: parsedNumber?.isValid(),
    countryCode: parsedNumber?.countryCallingCode,
    nationalPhoneNumber: parsedNumber?.nationalNumber,
    fullPhoneNumber: sanitizePhone(parsedNumber?.number),
  };
}

const PhoneNumber = (props) => {
  const { onPhoneChange, error, onIsValidPhoneNumberChange, containerClassName, ...restProps } = props;
  const [number, setNumber] = useState("");
  const prevMyState = useRef(number);

  useEffect(() => {
    var current_action = -1;
    if (number.length > prevMyState.current.length) {
      current_action = 1;
    } else if (number.length < prevMyState.current.length) {
      current_action = 0;
    }
    prevMyState.current = number;
    if (number.length > 0) {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_PHONE_NUMBER.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_PHONE_NUMBER.event_version,
        {
          type_character: current_action,
          entered_phone_number: number,
        }
      );
    }
  }, [number]);

  const handleChange = (isValid, nationalPhoneNumber, { dialCode }) => {
    const number = "+" + dialCode + nationalPhoneNumber;
    const data = parsePhone(number);
    onIsValidPhoneNumberChange && onIsValidPhoneNumberChange(data.isValid);
    setNumber(nationalPhoneNumber);
    onPhoneChange && onPhoneChange(data);
  };

  const handleClear = () => {
    setNumber("");
    onPhoneChange && onPhoneChange({});
  };

  const handleSelectFlag = (currentNumber, { dialCode, name }) => {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SELECT_COUNTRY.event_name,
      ANALYTIKA_EVENTS.IDNW_SELECT_COUNTRY.event_version,
      {
        phone_country: name,
      }
    );
    Analytika.fireEvent("phone_number_flag_selected");

    const number = "+" + dialCode + currentNumber;
    const data = parsePhone(number);

    setNumber(currentNumber);
    onPhoneChange && onPhoneChange(data);
  };
  return (
    <Fragment>
      <div className="container">
        <IntlTelInput
          containerClassName={clsx("intl-tel-input", containerClassName)}
          inputClassName={clsx({ "tel-error": error })}
          preferredCountries={["ae", "eg", "pk", "sa"]}
          separateDialCode={true}
          value={number}
          onPhoneNumberChange={handleChange}
          onPhoneNumberFocus={() => {
            Analytika.fireEvent("Idtn_Web_phone_number_focused");
          }}
          onFlagClick={() => {
            eventAnalytics.publish(
              ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
              ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
              {
                page_name: "country_list",
              }
            );
            Analytika.fireEvent("Idtn_Web_phone_number_flag_clicked");
          }}
          onSelectFlag={handleSelectFlag}
          {...restProps}
        />
        {error && number?.length > 0 && (
          <div className="icon-btn">
            <IconButton aria-label="clear" size="small" onClick={handleClear}>
              <ClearIcon fontSize="inherit" htmlColor="#F7430A" />
            </IconButton>
          </div>
        )}
      </div>
      {error && (
        <div className="error-msg">
          <p>{error}</p>
        </div>
      )}
    </Fragment>
  );
};

PhoneNumber.propTypes = {
  onPhoneChange: Types.func.isRequired,
  onIsValidPhoneNumberChange: Types.func,
  error: Types.string,
  containerClassName: Types.string,
};

PhoneNumber.defaultProps = {
  error: null,
};

export default PhoneNumber;
