import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Types from "prop-types";
import LoadingSpinner from "../LoadingSpinner";
import { fetchReCaptchaToken } from "../Guard";
import SuccessTransactionLogo from "../../images/successTransaction.svg";
import { IdpClientContext } from "../../idpClient";

export const CSRF_QUERY_PARAM = "csrf";
export const CSRF_SESSION_STORAGE_FIELD = "idp." + CSRF_QUERY_PARAM;

function RedirectToLogin(props) {
  const { fieldName, value, csrf, rememberMe } = props;
  const [recaptcha, setRecaptcha] = useState("");
  const form = useRef(null);

  useEffect(() => {
    fetchReCaptchaToken("signup_redirect_to_login").then((recaptcha) => {
      setRecaptcha(recaptcha);
      form.current.submit();
    });
  }, [form]);

  return (
    <div>
      <LoadingSpinner />
      <form
        action={`${process.env.REACT_APP_IDENTITY_HOST}/login`}
        method="post"
        ref={form}
      >
        <input
          name="g-recaptcha-response"
          type="hidden"
          value={recaptcha}
          readOnly
        />
        <input name={fieldName} type="hidden" value={value} readOnly />
        <input name="_csrf" hidden={true} value={csrf} readOnly />
        <input name="remember" hidden={true} value={rememberMe} readOnly />
      </form>
    </div>
  );
}

const RedirectPropTypes = {
  fieldName: Types.string.isRequired,
  value: Types.string.isRequired,
  csrf: Types.string.isRequired,
  rememberMe: Types.bool.isRequired,
  isRegistration: Types.bool,
};

export const RedirectToLoginEnhanced = ({
  fieldName,
  value,
  csrf,
  rememberMe,
  isRegistration,
}) => {
  const { t } = useTranslation();
  const [recaptcha, setRecaptcha] = useState("");
  const form = useRef(null);
  const [seconds, setSeconds] = useState(5);
  const [reCaptchaError, setReCaptchaError] = useState("");
  const { clientName: idpClientName } = useContext(IdpClientContext);

  const clientName = idpClientName || "Careem";

  const submitWithRecaptcha = () => {
    fetchReCaptchaToken("signup_redirect_to_login")
      .then((recaptcha) => {
        setRecaptcha(recaptcha);
        form.current.submit();
      })
      .catch((err) => {
        setReCaptchaError(err);
      });
  };

  useEffect(() => {
    if (seconds < 2) {
      submitWithRecaptcha();
      return;
    }
    setTimeout(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);
  }, [form, seconds]);

  return (
    <div>
      <div className="express-success-screen-line">
        <img
          src={SuccessTransactionLogo}
          alt="Welcome back!"
          style={{ width: 325 }}
        />
      </div>
      {isRegistration ? (
        <h1> {t("success_redirect:title_registered", { clientName })} </h1>
      ) : (
        <h1> {t("success_redirect:title_login", { clientName })} </h1>
      )}
      {!reCaptchaError ? (
        <div className="express-extra-text show express-success-screen-line">
          {t("success_redirect:hint", { seconds })}
        </div>
      ) : (
        <div className="invalid show express-common">
          {t("spring_security_error:RECAPTCHA_FAILED_VERIFICATION")}
        </div>
      )}

      <form
        action={`${process.env.REACT_APP_IDENTITY_HOST}/login`}
        method="post"
        ref={form}
      >
        <input
          name="g-recaptcha-response"
          type="hidden"
          value={recaptcha}
          readOnly
        />
        <input name={fieldName} type="hidden" value={value} readOnly />
        <input name="_csrf" hidden={true} value={csrf} readOnly />
        <input name="remember" hidden={true} value={rememberMe} readOnly />
        <input
          className="btn-express btn-express-primary express-success-screen-line"
          type="button"
          value={t("common:continue")}
          name="continue-redirect-btn"
          onClick={submitWithRecaptcha}
        />
      </form>
    </div>
  );
};

RedirectToLogin.propTypes = RedirectPropTypes;
RedirectToLoginEnhanced.propTypes = RedirectPropTypes;

export default RedirectToLogin;
