import React from "react";
import OtpView from "../otp/OtpView";
import queryString from "query-string";
import RedirectToLogin, {
  RedirectToLoginEnhanced,
  CSRF_QUERY_PARAM,
  CSRF_SESSION_STORAGE_FIELD,
} from "../common/RedirectToLogin";
import Types from "prop-types";
import SignupForm, {
  SIGNUP_CORPORATE,
  SIGNUP_WITH_APPLE,
  SIGNUP_WITH_GOOGLE,
  SIGNUP_WITH_PHONE,
} from "./SignupForm";
import { signupWithOnboarder } from "../gateway/SignupGateway";
import { parsePhone } from "../common/PhoneNumber";
import CorporateSignupError from "./CorporateSignupError";
import i18n from "i18next";
import { Trans } from "react-i18next";
import { syncSessionStorageAndExtractEmail } from "../common/AppleButton";
import { ARGUS_APM } from "../../apm";
import { initProfiling } from "../device";
import Galileo, { GalileoContext } from "../../galileo";
import { getErrorIdFromErrorCode } from "../../utils/errorMapping";
import Analytika from "../../analytika";
import eventAnalytics from "../../analytikaV2";
import BlockedUser from "../blockedUser/BlockedUser";
import { ANALYTIKA_EVENTS } from "../../utils/constants";
import { bugsnagInstance } from "../../bugsnag";

// views:
const SIGNUP_FORM_VIEW = "signup_form";
const OTP_VIEW = "otp";
const CORPORATE_SIGNUP_ERROR = "corporate_signup_error";
const REDIRECT_TO_IDP = "redirect_to_idp";
const REDIRECT_TO_BLOCKED_USER = "redirect_to_blocked_user";

const APPLE_TOKEN_QUERY_PARAM = "appleIdToken";
const GOOGLE_TOKEN_QUERY_PARAM = "googleIdToken";

//redirect to IDP variants:
const REDIRECT_WITH_PROOF_TOKEN = "proofToken";

export default class SignupOrchestrator extends React.Component {
  constructor(props) {
    super(props);
    Analytika.fireEvent("Idtn_Web_SingupForm_View", {
      phonenumber: this.state?.phone?.fullPhoneNumber,
    });
    let defaultState = {
      submitting: false,
      view: SIGNUP_FORM_VIEW,

      phone: null,

      fullname: "",
      email: "",
      invitationCode: "",
      password: "",
      hideInvitationCode: null,
      visibleFields: {
        fullname: true,
        phone: true,
        email: false,
        password: true,
        invitationCode: false,
        appleButton: true,
        googleButton: true,
      },

      otp: null,
      signupSessionId: null,

      corporateInvitationToken: null,
      appleIdToken: null,
      googleIdToken: null,
      checkIfSocialIdAlreadyLinked: false,

      onboardExperience: null,

      recaptcha: null,
      redirectToIdpParams: {
        fieldName: null,
        value: null,
      },
      csrf: sessionStorage.getItem(CSRF_SESSION_STORAGE_FIELD),
      error: null,
      listOfWeakPasswords: [],
      isInvalidPassword: null,
      blockedErrorState: "",
      blockedErrorMsg: "",
    };
    this.processUrlParams(defaultState);
    Galileo.fetchWeakPasswords();
    this.state = defaultState;
  }

  static contextType = GalileoContext;

  processUrlParams(defaultState) {
    const { location, history } = this.props;
    let param = location.search;
    const {
      csrf,
      fullMobileNumber,
      otp,
      appleIdToken,
      googleIdToken,
      showEmail,
      email,
      hideInvitationCode,
      showApple,
      showGoogle,
      corporateInvitationToken,
      clientId,
    } = queryString.parse(param);
    if (csrf) {
      sessionStorage.setItem(CSRF_SESSION_STORAGE_FIELD, csrf);
      defaultState.csrf = csrf;
    }

    if (email) {
      defaultState.email = email;
    }

    defaultState.otp = otp;
    defaultState.appleIdToken = appleIdToken;
    defaultState.googleIdToken = googleIdToken;
    defaultState.corporateInvitationToken = corporateInvitationToken;
    defaultState.clientId = clientId;

    if (fullMobileNumber) {
      defaultState.phone = parsePhone(fullMobileNumber);
      defaultState.visibleFields.phone = !(defaultState.phone?.isValid && otp); //disable phone input if verified
    }

    defaultState.visibleFields.email = showEmail === "true";
    defaultState.visibleFields.invitationCode = hideInvitationCode === "false";
    defaultState.visibleFields.appleButton = showApple === "true";
    defaultState.visibleFields.googleButton = showGoogle === "true";

    this.configureFieldVisibilityForSocial(defaultState);
    this.configureFieldsVisibilityForCorporates(defaultState);

    const queryParams = new URLSearchParams(param);
    this.deleteParamIfPresent(APPLE_TOKEN_QUERY_PARAM, queryParams);
    this.deleteParamIfPresent(GOOGLE_TOKEN_QUERY_PARAM, queryParams);
    this.deleteParamIfPresent(CSRF_QUERY_PARAM, queryParams);
    history.replace({
      search: queryParams.toString(),
    });
    return defaultState;
  }

  configureFieldVisibilityForSocial(defaultState) {
    if (
      defaultState.appleIdToken?.length > 0 ||
      defaultState.googleIdToken?.length > 0
    ) {
      defaultState.visibleFields.fullname = false;
      defaultState.visibleFields.password = false;
      defaultState.visibleFields.appleButton = false;
      defaultState.visibleFields.googleButton = false;
    }
    return defaultState;
  }

  configureFieldsVisibilityForCorporates(defaultState) {
    if (defaultState.corporateInvitationToken?.length > 0) {
      defaultState.visibleFields.fullname = false;
      defaultState.visibleFields.email = false;
      defaultState.visibleFields.password = false;
      defaultState.visibleFields.invitationCode = false;
      defaultState.visibleFields.appleButton = false;
      defaultState.visibleFields.googleButton = false;
    }
    return defaultState;
  }

  setGoogleButtonVisibility(toggle) {
    this.setState({
      visibleFields: { ...this.state.visibleFields, googleButton: toggle },
    });
  }

  componentDidMount() {
    Galileo.fetchWeakPasswords().then((_list) => {
      if (_list) {
        this.setState({ listOfWeakPasswords: _list.split(",") });
      }
    });

    Galileo.fetchIsGoogleEnabledFlag().then((toggle) =>
      this.setGoogleButtonVisibility(
        this.state.visibleFields.googleButton && toggle
      )
    );

    //get user info if social token present
    if (this.state.appleIdToken?.length > 0) {
      this.onAppleIdChange(
        syncSessionStorageAndExtractEmail(this.state.appleIdToken)
      );
    }
  }

  handleUnknown400Errors(error) {
    bugsnagInstance.notify(error);
    ARGUS_APM.captureError(error?.errorCode);
    var errorMessage = i18n.t("spring_security_error:NO_RESPONSE");
    if (error?.errorCode && getErrorIdFromErrorCode(error?.errorCode)) {
      if (
        getErrorIdFromErrorCode(error?.errorCode) === "USER_BLOCKED_FOR_SIGNUP"
      ) {
        this.setState({
          view: REDIRECT_TO_BLOCKED_USER,
          blockedErrorState: error?.errorCode,
        });

        errorMessage = (
          <Trans
            i18nKey="spring_security_error:USER_BLOCKED_FOR_SIGNUP"
            t={this.props.t}
          >
            We cannot log you in at this time.
            <a href="https://help.careem.com/hc/en-us/requests/new?reason=account_issue">
              Please contact us for help.
            </a>
          </Trans>
        );
      } else if (
        getErrorIdFromErrorCode(error?.errorCode) === "EMAIL_ALREADY_USED"
      ) {
        errorMessage = (
          <Trans
            i18nKey="spring_security_error:EMAIL_ALREADY_USED"
            t={this.props.t}
          >
            A verified account with this email already exists.
            <a href="https://help.careem.com/hc/en-us/requests/new?reason=account_issue">
              Please contact us for help.
            </a>
          </Trans>
        );
      } else {
        errorMessage = i18n.t(
          "spring_security_error:" + getErrorIdFromErrorCode(error?.errorCode)
        );
      }
    } else if (error?.error_message) {
      errorMessage = error?.error_message;
    } else if (error?.errorMessage) {
      errorMessage = error?.errorMessage;
    } else if (error?.message) {
      errorMessage = error?.message;
    }
    this.setState({
      submitting: false,
      error: errorMessage,
    });
  }

  handleOnboarderErrors(error) {
    bugsnagInstance.notify(error);
    ARGUS_APM.captureError(error?.error);
    var errorMessage = i18n.t("spring_security_error:NO_RESPONSE");
    var errorCode = error?.additional_information?.error_code;
    if (errorCode && getErrorIdFromErrorCode(errorCode)) {
      if (
        getErrorIdFromErrorCode(error?.errorCode) === "USER_BLOCKED_FOR_SIGNUP"
      ) {
        this.setState({
          view: REDIRECT_TO_BLOCKED_USER,
          blockedErrorState: error?.errorCode,
          blockedErrorMsg: error?.error_description,
        });
      } else {
        errorMessage = i18n.t(
          "spring_security_error:" + getErrorIdFromErrorCode(errorCode)
        );
      }
    } else if (error?.error === "challenge_required") {
      if (error?.additional_information.challenge === "otp") {
        this.setState({
          view: OTP_VIEW,
        });
        return;
      } else {
        errorMessage = "Provide: " + error?.additional_information.challenge;
      }
    } else if (error?.error_description) {
      errorMessage = error?.error_description;
    }
    this.setState({
      submitting: false,
      error: errorMessage,
    });
  }

  splitFullName(fullname) {
    const parts = fullname?.split(/\s+/g);

    if (!parts) return null;

    if (parts.length === 1) {
      return { firstName: parts[0], lastName: "" };
    } else if (parts.length > 1) {
      return {
        firstName: parts.slice(0, -1).join(" "),
        lastName: parts.slice(-1).join(" "),
      };
    }
    return null;
  }

  async signupUserWithOnboarder(otp) {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
      {
        request_type: "signup_form",
      }
    );
    let profiling = await initProfiling().catch((ex) => {
      bugsnagInstance.notify(ex);
      console.log(ex);
    });
    return signupWithOnboarder({
      deviceId: this.state.device,
      onboard_experience_id: this.state.onboardExperience,
      phone_number: `${this.state.phone?.countryCode}${this.state.phone?.nationalPhoneNumber}`,
      full_name: this.state.fullname,
      password: this.state.password,
      apple_id_token: this.state.appleIdToken,
      google_id_token: this.state.googleIdToken,
      otp: otp,
      email: this.state.email,
      clientId: this.state.clientId,
      profiling: profiling,
    })
      .then((data) => {
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "signup_form",
            request_status: "success",
          }
        );

        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SUCCESS_SIGNUP.event_name,
          ANALYTIKA_EVENTS.IDNW_SUCCESS_SIGNUP.event_version
        );
        Analytika.fireEvent("Idtn_Web_SingupForm_Success", {
          phonenumber: this.state.phone?.fullPhoneNumber,
        });
        // INFO: this will ensure to send all event that are in staged state
        eventAnalytics.flush().then(() => {
          this.redirectToLogin({
            fieldName: REDIRECT_WITH_PROOF_TOKEN,
            value: data?.token,
          });
        });
      })
      .catch((err) => {
        bugsnagInstance.notify(err);
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "signup_form",
            request_status: "fail",
            error_code_string:
              err.response?.data?.error?.additional_information?.error_code?.toString(),
            error_description: err.response?.data?.error,
          }
        );
        Analytika.fireEvent("Idtn_Web_SingupForm_Failed", {
          phonenumber: this.state.phone?.fullPhoneNumber,
        });
        ARGUS_APM.captureError(err);
        this.setState({
          onboardExperience:
            err.response?.data.additional_information?.onboard_experience_id,
        });
        this.handleOnboarderErrors(err.response?.data);
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fullname?.length !== prevState.fullname?.length) {
      let currentAction = -1;
      if (this.state.fullname.length > prevState.fullname.length) {
        currentAction = 1;
      } else if (this.state.fullname.length < prevState.fullname.length) {
        currentAction = 0;
      }

      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_NAME.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_NAME.event_version,
        {
          type_character: currentAction,
          name_entered: this.state.fullname,
        }
      );
    }
  }

  async onOtpVerify(otp) {
    this.setState({ submitting: true });

    this.signupUserWithOnboarder(otp);
  }

  redirectToLogin(redirectToIdpParams) {
    this.setState({
      redirectToIdpParams: redirectToIdpParams,
      view: REDIRECT_TO_IDP,
    });
  }

  deleteParamIfPresent(paramName, queryParams) {
    if (queryParams.has(paramName)) {
      queryParams.delete(paramName);
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onPasswordChange(event) {
    const value = event.target.value;
    if (value.length === 1) {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_version
      );
    }
    var errorMessage = null;
    var isInvalidPassword = false;
    if (
      value &&
      this.state.listOfWeakPasswords.some(
        (_password) => _password.toLowerCase() === value.toLowerCase()
      )
    ) {
      isInvalidPassword = true;
      errorMessage = i18n.t("error:weak_password");
    }
    bugsnagInstance.notify(errorMessage);
    this.setState({
      error: errorMessage,
      [event.target.name]: event.target.value,
      isInvalidPassword: isInvalidPassword,
    });
  }

  onPhoneChange(phone) {
    this.setState({ phone });
  }

  getFlowType() {
    const {
      appleIdToken,
      googleIdToken,
      showEmail,
    } = this.state;
    if (showEmail) return SIGNUP_CORPORATE;
    if (appleIdToken) return SIGNUP_WITH_APPLE;
    if (googleIdToken) return SIGNUP_WITH_GOOGLE;
    return SIGNUP_WITH_PHONE;
  }

  onAppleIdChange(props) {
    this.setState(props);
    let { proofToken } = props;
    if (proofToken) {
      this.redirectToLogin({
        fieldName: REDIRECT_WITH_PROOF_TOKEN,
        value: proofToken,
      });
    } else {
      this.setState(this.configureFieldVisibilityForSocial(this.state));
    }
  }

  onGoogleIdChange(props) {
    this.setState(props);
    let { proofToken } = props;
    if (proofToken) {
      this.redirectToLogin({
        fieldName: REDIRECT_WITH_PROOF_TOKEN,
        value: proofToken,
      });
    } else {
      this.setState(this.configureFieldVisibilityForSocial(this.state));
    }
  }

  getIdentifier() {
    if (this.state.phone?.isValid) {
      return this.state.phone.fullPhoneNumber;
    }
    if (this.state.email?.length > 0) {
      return this.state.email;
    }
    return null;
  }

  async onSubmit(event) {
    event.preventDefault();
    if (!this.state.phone?.isValid) {
      bugsnagInstance.notify("Please enter correct phone number.");
      this.setState({
        submitting: false,
        error: "Please enter correct phone number.",
      });
      return;
    }

    this.setState({
      submitting: true,
      error: null,
    });

    eventAnalytics.setFlowName("phone");
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
      {
        button_name: "signup",
      }
    );
    Analytika.fireEvent("Idtn_Web_SingupForm_Submit", {
      phonenumber: this.state.phone?.fullPhoneNumber,
    });

    if (this.state.otp) {
      this.signupUserWithOnboarder(this.state.otp);
    } else {
      this.setState({
        view: OTP_VIEW,
      });
    }
  }

  redirectToLoginHandler() {
    this.setState({ view: SIGNUP_FORM_VIEW, error: null, phone: null });
    window.location.href = process.env.REACT_APP_IDENTITY_REDIRECT;
  }

  render() {
    const {
      error,
      submitting,
      view,
      fullname,
      email,
      password,
      visibleFields,
    } = this.state;
    const { isEnhancedFormEnabled } = this.context;

    switch (view) {
      case SIGNUP_FORM_VIEW:
        return (
          <SignupForm
            error={error}
            fullname={fullname}
            email={email}
            password={password}
            phoneNumber={this.state.phone?.fullPhoneNumber}
            visibleFields={visibleFields}
            flowType={this.getFlowType()}
            onChange={this.onChange.bind(this)}
            onPasswordChange={this.onPasswordChange.bind(this)}
            onPhoneChange={this.onPhoneChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            onAppleIdChange={this.onAppleIdChange.bind(this)}
            onGoogleIdChange={this.onGoogleIdChange.bind(this)}
            submitting={submitting}
            isInvalidPassword={this.state.isInvalidPassword}
            isEnhancedFormEnabled={isEnhancedFormEnabled}
          />
        );
      case OTP_VIEW:
        return (
          <OtpView
            identifier={this.state.phone.fullPhoneNumber}
            clientId={this.state.clientId}
            onOtpVerify={this.onOtpVerify.bind(this)}
            submitting={submitting}
            submitError={error}
            isEnhancedFormEnabled={isEnhancedFormEnabled}
          />
        );
      case REDIRECT_TO_IDP:
        return isEnhancedFormEnabled ? (
          <RedirectToLoginEnhanced
            fieldName={this.state.redirectToIdpParams.fieldName}
            value={this.state.redirectToIdpParams.value}
            csrf={this.state.csrf}
            isRegistration={false} // TODO figure out flow
          />
        ) : (
          <RedirectToLogin
            fieldName={this.state.redirectToIdpParams.fieldName}
            value={this.state.redirectToIdpParams.value}
            csrf={this.state.csrf}
          />
        );
      case CORPORATE_SIGNUP_ERROR:
        return <CorporateSignupError />;
      case REDIRECT_TO_BLOCKED_USER:
        return (
          <BlockedUser
            blockedErrorState={this.state.blockedErrorState}
            fullPhoneNumber={this.state.phone?.fullPhoneNumber}
            redirectToLoginHandler={this.redirectToLoginHandler.bind(this)}
          />
        );
      default:
        return <></>;
    }
  }
}

SignupOrchestrator.propTypes = {
  history: Types.object.isRequired,
  location: Types.object,
  t: Types.func,
};
