import React from "react";
import  GiftIcon from "../../../images/giftIcon.svg";
import DiscountIcon from "../../../images/discountIcon.svg";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import { Trans,useTranslation, withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

function BannerPromoLine(props) {
  const {t , promoValue, promoType, promoOutcome, classes} = props
  const  {i18n} = useTranslation();
  const dir = i18n.dir()
  return (
  <div className={classes.promoContainer}>
        
    {
      promoOutcome === 'CASHBACK'? 
      <Typography className={classes.promoText} style={{direction: `${dir}`}}>
        <img src={GiftIcon} alt="Gift Icon" className={classes.offerIcon} style={{verticalAlign:"top"}}/> 
        <Trans t={t} i18nKey="one_click_checkout:banner_promo_line_cashback">
          <span className={classes.amount}>{{promoValue: promoValue}}{{ promoType: promoType === '%' ? '%': `${t("one_click_checkout:promoType")}`}}</span>
        </Trans>
      </Typography>
    :
      <Typography className={classes.promoText} style={{direction: `${dir}`}}>
        <img src={DiscountIcon} alt="Discount Icon" className={classes.offerIcon} style={{verticalAlign:"top"}}/> 
        <Trans t={t} i18nKey="one_click_checkout:banner_promo_line_discount">
          <span className={classes.amount}>{{promoValue: promoValue}}{{ promoType: promoType === '%' ? '%': `${t("one_click_checkout:promoType")}`}}</span>
        </Trans>
      </Typography>
    }
   
  </div>
  );
}


BannerPromoLine.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func,
    i18n: PropTypes.object,
    promoValue: PropTypes.number,
    promoType: PropTypes.string,
    promoOutcome: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(BannerPromoLine));

/**
 *    {
      promoOutcome === 'CASHBACK'? 
      <Trans t={t}>
      <p className={classes.promoText}>
      <img src={GiftIcon} className={classes.offerIcon} alt="Gift Icon" />
      <span> Earn</span>
      <b> {promoValue} {promoType} Cashback </b>
      <span>to your Careem wallet </span>
    </p> 
    </Trans>
    :
    <Trans t={t} >
    <p className={classes.promoText}>
    <img src={DiscountIcon} className={classes.offerIcon} alt="Gift Icon" />
      <span> Unlock </span>
      <b> {promoValue} {promoType} Discount </b>
      <span>to your Cart </span>
  </p> 
  </Trans>

    }
 */