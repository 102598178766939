import React, { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { ReCaptcha } from "../../../Guard";
import { verifyApproveRequestStatus } from "../../../gateway/LoginGateway";
import Analytika from "../../../../analytika";
import getMobileOS from "../../../../utils/getMobileOS";
import { MAX_RETRIES } from "../constants";
import lottieJson from "../../../../images/lotties/open_careem_app.json";
import styles from "../styles";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
import eventAnalytics from "../../../../analytikaV2";

let pollingInstance = null;

function PushNotificationView(props) {
  const { phoneNumber, merchant, token, onConfirm, onReject, doLogin, invoiceId } = props;
  const [pushId, setPushId] = useState(token?.id);
  const [currentRetries, setCurrentRetries] = useState(0);

  const { isIOS, isAndroid } = getMobileOS();

  useEffect(() => {
    Analytika.fireEvent("push_screen_opened");
    eventAnalytics.publish("pyck_view_ck_page", null, {
      screen_name: "await_verification",
      flow: "identity",
      variant: isMobile ? "mobile" : "desktop"
    });
  }, [props.invoiceId]);

  useEffect(() => {
    pollingInstance = verifyApproveRequestStatus(pushId);
    const delay = currentRetries < 2 ? 1000 : 5000;
    pollingInstance.poll(delay).get((response) => {
      const { status } = response.data;
      switch (status) {
        case "confirmed": {
          Analytika.fireEvent("push_request_confirmed");
          eventAnalytics.publish("pyck_response_ck_button", null, {
            flow: "identity",
            status: "success",
            screen_name: "verification",
            button_name: "yes_it_is_me",
          });
          onConfirm(pushId);
          // Cancel polling by returning false
          return false;
        }
        case "rejected": {
          Analytika.fireEvent("push_request_rejected");
          eventAnalytics.publish("pyck_response_ck_button", null, {
            flow: "identity",
            status: "rejected",
            screen_name: "verification",
            button_name: "yes_it_is_me",
          });
          onReject();
          // Cancel polling by returning false
          return false;
        }
        default:
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRetries]);

  const onRetry = useCallback(() => {
    Analytika.fireEvent("retry_button_clicked", {
      currentRetries
    });
    pollingInstance.cancel("get");
    if (currentRetries < MAX_RETRIES) {
      doLogin({ is_retry: true }).then((retryToken) => {
        setPushId(retryToken?.id);
        setCurrentRetries((curr) => ++curr);
      });
    } else {
      onReject("maxTriesReached");
      Analytika.fireEvent("max_retry_reached");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRetries, pollingInstance]);

  const onChangePhone = () => {
    Analytika.fireEvent("change_phone_number_clicked");
    pollingInstance.cancel("get");
    onReject("changePhone");
  };

  return (
    <Box height="537px">
      <Helmet>
        <title>Push Notification</title>
      </Helmet>
      <Box display="flex" justifyContent="center">
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 250, height: 250 }}
        />
      </Box>
      {(isIOS || isAndroid) && token.web_deeplink ? (
        <MobileView
          phoneNumber={phoneNumber}
          merchant={merchant}
          onRetry={onRetry}
          deeplink={token.web_deeplink}
          onChangePhone={onChangePhone}
          invoiceId={invoiceId}
          promoValue={props.promoValue}
          promoType={props.promoType}
          promoOutcome={props.promoOutcome}
        />
      ) : (
        <DesktopView
          phoneNumber={phoneNumber}
          merchant={merchant}
          onRetry={onRetry}
          onChangePhone={onChangePhone}
          promoValue={props.promoValue}
          promoType={props.promoType}
          promoOutcome={props.promoOutcome}
        />
      )}
      <ReCaptcha />
    </Box>
  );
}

PushNotificationView.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  merchant: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
  promoValue: PropTypes.string,
  promoType: PropTypes.string,
  promoOutcome: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(PushNotificationView));
