import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import Types from "prop-types";
import { Grid } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ButtonEnhanced from "./enhanced/ButtonEnhanced";


const ResetAccountDialog = ({ visible, onSubmit, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container>
          <Grid className="express-modal-icon-block" xs={1} item>
            <InfoOutlinedIcon style={{ fontSize: 24 }} />
          </Grid>
          <Grid xs={11} item>
            <h3 className="heading-h3-express express-text-primary start express-common">
              {t("reset_account:title")}
            </h3>
            <label className="express-form-field-label show express-common">
              {t("reset_account:description")}
            </label>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "0px 32px 24px" }}>
        <ButtonEnhanced btnType="tertiary" onClick={onClose}>
          {t("reset_account:cancel")}
        </ButtonEnhanced>
        <ButtonEnhanced
          btnType="danger"
          onClick={onSubmit}
          variant="contained"
          autoFocus
        >
          {t("reset_account:submitBtn")}
        </ButtonEnhanced>
      </DialogActions>
    </Dialog>
  );
};

ResetAccountDialog.propTypes = {
  visible: Types.bool.isRequired,
  onSubmit: Types.func.isRequired,
  onClose: Types.func.isRequired,
};

export default ResetAccountDialog;
