import React from "react";
import { Trans, withTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export class AlertAcknowledged extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <LanguageSelector />
        <h1>
          <CheckCircleOutlineIcon className={"largeIcon successColor"} />
        </h1>
        <h1>{t("alert:alert_acknowledged_title")}</h1>
        <hr />
        <p style={{ textAlign: "center" }}>
          {t("alert:alert_acknowledged_message")}
        </p>
        <h5>
          <Trans i18nKey="alert:alert_acknowledged_help" t={t}>
            Please contact our
            <a href="https://help.careem.com/hc/requests/new">Care Team</a> if
            you have further questions
          </Trans>
        </h5>
      </div>
    );
  }
}

export default withTranslation()(AlertAcknowledged);
