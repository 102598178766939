import React, { useState } from "react";
import Types from "prop-types";
import CareemRebrandedLogo from "../../images/Careem_Rebranded_Logo.svg";
import { Popover, makeStyles } from "@material-ui/core";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { withTranslation, Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: 120,
  },
  iconButton: {
    cursor: "pointer",
    width: 40,
    height: 40,
    borderRadius: 20,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 1px 10px 0px rgba(96, 108, 116, 0.1)",
  },
  menuBtn: {
    padding: theme.spacing(1, 2),
    minWidth: 75,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(23,169,51,0.2)",
    },
  },
  menuPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 0),
    boxShadow: [
      "0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
      "0px 6px 16px 0px rgba(0, 0, 0, 0.08)",
      "0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
    ],
  },
}));

function OneClickHeader(props) {
  const {
    i18n,
    location: { search },
  } = props;

  const { acr_values: acrValues } = queryString.parse(search);

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const launchExitSurvey = () => {
    setAnchorEl(null);
    const matches = acrValues.match(/invoice_id:([a-z0-9-]+)/);
    const invoiceId = matches && matches.length ? matches[1] : null;
    if (invoiceId) {
      window.location.href = process.env.REACT_APP_ONECLICK_CHECKOUT_SURVEY_URL.replace(
        "{invoice_id}",
        invoiceId
      );
    }
  };
  const dir = i18n.dir();
  if (!acrValues) {
    return null;
  }
  return (
    <div dir={dir} className={classes.root}>
      <img src={CareemRebrandedLogo} alt="Careem logo" className={classes.logo} />
      <span className={classes.iconButton} onClick={handleOpenMenu}>
        <MoreHoriz fontSize="large" />
      </span>
      <Popover
        classes={{
          paper: classes.menuPaper,
        }}
        PaperProps={{
          elevation: 0,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: dir === "rtl" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: dir === "rtl" ? "left" : "right",
        }}
      >
        <div
          data-test-id="close-btn"
          dir={dir}
          className={classes.menuBtn}
          onClick={launchExitSurvey}
        >
          <Trans i18nKey="common:close">Close</Trans>
        </div>
      </Popover>
    </div>
  );
}

OneClickHeader.propTypes = {
  i18n: Types.object.isRequired,
  location: Types.object.isRequired,
};

export const OneClickHeaderUnwrapped = OneClickHeader;

export default withTranslation()(withRouter(OneClickHeader));
