import React from "react";
import Types from "prop-types";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import AppleButton from "../../common/AppleButton";

export default class AppleLoginForm extends React.Component {
  render() {
    const { error, onAppleTokenChanged } = this.props;
    return (
      <form>
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        {error && <div className="invalid show">{error}</div>}
        <div className="center">
          <AppleButton onChange={onAppleTokenChanged} />
        </div>

        <ReCaptcha />
      </form>
    );
  }
}

AppleLoginForm.propTypes = {
  onAppleTokenChanged: Types.func.isRequired,
};
