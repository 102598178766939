import axios from "axios";
import { initDeviceId } from "./components/device";
import { v4 as uuidv4 } from "uuid";

class Analytika {
  constructor() {
    this.sessionAttrs = {
      sessionUuid: uuidv4(),
      appName: process.env.REACT_APP_ANALYTIKA_EVENT_SOURCE,
      userAgent: navigator.userAgent,
    };
    this.client = axios.create({
      baseURL: process.env.REACT_APP_ANALYTIKA_PATH,
      headers: {
        "event-source": process.env.REACT_APP_ANALYTIKA_EVENT_SOURCE,
        "api-key": process.env.REACT_APP_ANALYTIKA_API_KEY,
      },
    });
  }

  setSessionAttr(attrs = {}) {
    Object.assign(this.sessionAttrs, attrs);
  }

  async fireEvent(eventName, attributes = {}) {
    try {
      const timestamp = Date.now();
      const payload = {
        sessionAttributes: Object.assign(
          {
            deviceId: await initDeviceId(),
          },
          this.sessionAttrs
        ),
        events: [
          {
            timestamp,
            eventName,
            ...attributes,
          },
        ],
      };
      await this.client.post("/v3/publish-event", payload);
    } catch (err) {
      console.log("Error during sending analytika", err);
    }
  }
}

export default new Analytika();
