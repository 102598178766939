import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

import LoadingSpinner from "../LoadingSpinner";
import OneClickErrorView from "../one-click/view/OneClickErrorView";
import { isAndroid, isIOS } from "../../utils/getMobileOS";
import { APP_STORE_URL, PLAY_STORE_URL } from "../../utils/constants";

function SignIn() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAndroid && !isIOS) {
      setLoading(false);
      return;
    }
    if (isAndroid) window.location.replace(PLAY_STORE_URL, "_self");
    if (isIOS) window.location.replace(APP_STORE_URL, "_self");
  }, []);

  return (
    <Box
      minHeight={400}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <OneClickErrorView
          heading={"We couldn’t verify you"}
          description={"Please install the Careem app and try again."}
          showUpdateButton
        />
      )}
    </Box>
  );
}

export default SignIn;
