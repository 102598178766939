import React from "react";
import axios from "axios";
import queryString from "query-string";
import "./ClientManagement.css";
import ClientManagementAssociation from "./ClientManagementAssociation";

export default class ClientManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associations: [],
      token: queryString.parse(props.location.hash).access_token,
      callBackUrl: `${window.location.origin + window.location.pathname}`,
    };

    this.removeAssociation = this.removeAssociation.bind(this);
  }

  componentDidMount() {
    document.title = "Clients management | Careem";
    const { token } = this.state;

    if (!token) {
      this.redirectToLogin();
    } else {
      this.loadAssociations(token);
    }
  }

  loadAssociations(token) {
    axios
      .get(`${process.env.REACT_APP_IDENTITY_HOST}/api/approved`, {
        headers: {
          Authorization: `BEARER ${token}`,
        },
      })
      .then((response) => {
        this.setState({ associations: response.data });
      })
      .catch((err) => {
        // In case of error, redirect to login again.
        // Most likely case of error is expired token,
        // hence logging in again in will fix that problem
        console.error(err);
        this.redirectToLogin();
      });
  }

  removeAssociation(clientId) {
    const { token } = this.state;
    axios
      .delete(
        `${
          process.env.REACT_APP_IDENTITY_HOST
        }/api/approved/clients/${encodeURIComponent(clientId)}`,
        {
          headers: {
            Authorization: `BEARER ${token}`,
          },
        }
      )
      .then((_) => {
        this.loadAssociations(this.state.token);
      });
  }

  // Redirect to get access token
  redirectToLogin() {
    window.location.href = `${
      process.env.REACT_APP_IDENTITY_HOST
    }/authorize?response_type=token&client_id=${
      process.env.REACT_APP_CLIENT_MANAGEMENT_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      this.state.callBackUrl
    )}&acr_values=merchant:TestMerchant invoice_id:123abc`;
  }

  createAssociationEntry(association) {
    return (
      <li key={association.clientId}>
        <ClientManagementAssociation
          name={association.clientName}
          logoUri={association.logoUri}
          removeAssociationCallback={() =>
            this.removeAssociation(association.clientId)
          }
        />
      </li>
    );
  }

  render() {
    const { associations } = this.state;
    const associationEntries = associations.map(
      this.createAssociationEntry.bind(this)
    );

    return (
      <div className="client-management">
        <h2>Manage Access</h2>
        <p>The following applications currently have access to your account:</p>
        <hr />
        <ul>
          {associationEntries.length !== 0 && associationEntries}
          {associationEntries.length === 0 && (
            <span style={{ fontWeight: "bold" }}>
              {" "}
              You have no applications associated with your account.
            </span>
          )}
        </ul>
      </div>
    );
  }
}
