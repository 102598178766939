import axios from "axios";

const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/client/otp`;

export async function verifyOtp({
  identifier,
  code,
  guard,
  clientId,
  deviceId,
}) {
  let config = {
    headers: {
      "x-careem-device-id": deviceId,
    },
  };
  return axios.post(
    `${endpoint}/verification`,
    {
      identifier,
      code,
      guard,
      client_id: clientId,
    },
    config
  );
}

export async function sendOtp({ identifier, type, guard, clientId, deviceId }) {
  let config = {
    headers: {
      "x-careem-device-id": deviceId,
    },
  };
  return axios.post(
    endpoint,
    {
      identifier,
      type,
      guard,
      client_id: clientId,
      multiTimeUse: false,
    },
    config
  );
}
