import React from "react";
import Types from "prop-types";
import GoogleAnalytics from "react-ga";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Analytika from "../analytika";
import eventAnalytics from "../analytikaV2";
import OneClickHeader from "./one-click-header/OneClickHeader";
import IdpClientService, { IdpClientContext } from "../idpClient";
import HeaderEnhanced from "./common/enhanced/HeaderEnhanced";
import Galileo, { GalileoContext } from "../galileo";
import { ARGUS_APM } from "../apm";
import LoadingSpinner from "./LoadingSpinner";
import { initDeviceId } from "./device";

GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

export const trackPage = async (page) => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.pageview(page);
  Analytika.setSessionAttr({ page });
  eventAnalytics.setFlowName(page);
  eventAnalytics.setDeviceId(await initDeviceId());
  eventAnalytics.setClientId(IdpClientService.getClientId());
};

const styles = (theme) => ({
  paper: {
    padding: "2em 3em",
    background: "var(--background-primary) !important",
    borderRadius: "8px",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      boxShadow: "0px 1px 10px rgba(96, 108, 116, 0.1)",
    },
  },
  boxTertiary: {
    background: "var(--background-tertiary) !important",
    minHeight: "100vh",
  },
  boxPrimary: {
    background: "var(--background-primary) !important",
    minHeight: "50vh",
  },
});

const initialState = {
  idpClientData: {
    clientId: "",
    logoUri: "",
    clientName: "Careem",
    isLoading: true,
  },
  galileoVars: {
    isEnhancedFormEnabled: false,
    isLoading: true,
  },
};

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    const { location } = this.props;
    const { pathname: page } = location;
    const clientId = IdpClientService.getClientId();
    if (clientId) {
      IdpClientService.fetchIdpClientData(clientId)
        .then(({ data }) => {
          this.setState({ idpClientData: { ...data, isLoading: false } });
        })
        .catch((err) => {
          ARGUS_APM.captureError(err);
          this.setState({
            idpClientData: { isLoading: false },
          });
        });
      Galileo.fetchIsEnhancedFormEnabled(clientId)
        .then((isEnhancedFormEnabled) => {
          this.setState({
            galileoVars: { isEnhancedFormEnabled, isLoading: false },
          });
        })
        .catch((err) => {
          ARGUS_APM.captureError(err);
          this.setState({
            galileoVars: { isLoading: false },
          });
        });
    } else {
      this.setState({
        idpClientData: { isLoading: false },
        galileoVars: { isLoading: false },
      });
    }

    trackPage(page);
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (currentPage !== nextPage) {
      trackPage(nextPage);
    }
  }

  render() {
    const { children, classes } = this.props;
    const { galileoVars, idpClientData } = this.state;
    const { isEnhancedFormEnabled, isLoading } = galileoVars;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <GalileoContext.Provider value={galileoVars}>
          <IdpClientContext.Provider value={idpClientData}>
            {isEnhancedFormEnabled && <HeaderEnhanced />}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={
                isEnhancedFormEnabled ? classes.boxPrimary : classes.boxTertiary
              }
            >
              {isEnhancedFormEnabled ? (
                <Grid
                  justifyContent="center"
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={10}
                  container
                  item
                >
                  <OneClickHeader />
                  {children}
                </Grid>
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                      <OneClickHeader />
                      {children}
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Box>
          </IdpClientContext.Provider>
        </GalileoContext.Provider>
      </>
    );
  }
}

Container.propTypes = {
  location: Types.object.isRequired,
  classes: Types.object.isRequired,
  children: Types.node.isRequired,
};

export default withStyles(styles)(Container);
