export const expressButtonStyles = {
  button: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    border: 0,
    borderRadius: 4,
    margin: "16px 0 8px",
    textAlign: "center",
    alignItems: "flex-start",
    textTransform: "none",
    letterSpacing: "normal",
    padding: "7px 16px",
    fontSize: 16,
    height: 40,
    lineHeight: "24px",
  },
  danger: {
    color: "var(--components-button-danger-text-default)",
    background: "var(--components-button-danger-background-default)",
    "& :hover": {
      color: "var(--components-button-danger-text-hover) !important",
    },
    "&.Mui-disabled": {
      color: "var(--components-button-primary-text-disabled)",
      background: "var(--components-button-primary-background-disabled)",
      border: `1px solid var(--components-button-primary-border-disabled)`,
    },
  },
  primary: {
    color: "var(--components-button-primary-text-default)",
    background:
      "var(--components-button-primary-background-default)",
    "& :hover": {
      color: "var(--components-button-primary-text-hover)",
    },
    "&.Mui-disabled": {
      color: "var(--components-button-primary-text-disabled)",
      background: "var(--components-button-primary-background-disabled)",
      border: `1px solid var(--components-button-primary-border-disabled)`,
    },
  },
  tertiary: {
    color: "var(--components-button-tertiary-text-default) !important",
    background:
      "var(--components-button-tertiary-background-default) !important",
    border: `1px solid var(--components-button-tertiary-border-default)`,
  },
  "&.Mui-disabled": {
    color: "var(--components-button-primary-text-disabled)",
    background: "var(--components-button-primary-background-disabled)",
    border: `1px solid var(--components-button-primary-border-disabled)`,
  },
  link: {
    backgroundColor: "transparent",
    border: "none",
    color: "var(--text-primary)",
    textDecoration: "none",
    cursor: "pointer",
    padding: "4px 5px",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    height: "auto",
    "&.Mui-disabled": {
      color: "var(--components-button-primary-text-disabled)",
    },
  },
};
