/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import Types from "prop-types";
import queryString from "query-string";
import i18n from "i18next";
import { Trans } from "react-i18next";

import {
  loginWithOneClick,
  proofApproveRequest
} from "../../gateway/LoginGateway";
import OneClickPhoneLoginForm from "../forms/OneClickPhoneLoginForm";
import { sanitizePhone, maskPhoneNumber } from "../../common/PhoneNumber";
import { ARGUS_APM } from "../../../apm";
import { fetchGuardToken } from "../../Guard";
import { PushNotificationView } from "./PushNotificationView";
import OneClickErrorView from "./OneClickErrorView";
import RedirectToLogin, {
  CSRF_SESSION_STORAGE_FIELD
} from "../../common/RedirectToLogin";
import Analytika from "../../../analytika";
import { isSessionStorage, parseAcrValues } from "../../../utils/helpers";

import Galileo from "../../../galileo";

import {
  ONE_CLICK_PHONE_LOGIN_FORM_VIEW,
  REDIRECT_TO_IDP,
  REDIRECT_TO_SIGNUP,
  PUSH_NOTIFICATION,
  UNREGISTERED_USER,
  REJECTED_USER,
  MAX_TRIES_REACHED
} from "./constants";
import { ANALYTICKA_EVENT_NO_INVOICE_ID } from "../../../utils/constants";
import eventAnalytics from "../../../analytikaV2";

function OneClickView(props) {
  const {
    location: { search }
  } = props;
  const { csrf, acr_values: acrValues } = queryString.parse(search);
  const externalParams = parseAcrValues(acrValues);
  const promoValue = externalParams?.promoValue ?? null;
  const promoType = externalParams?.promoType ?? null;
  const promoOutcome = externalParams?.promoOutcome ?? null;

  const [state, setState] = useState({
    submitting: false,
    view: ONE_CLICK_PHONE_LOGIN_FORM_VIEW,
    redirectToPush: null,
    clientId: null,
    rememberMe: true,
    error: null,
  });

  const invoiceId = externalParams?.invoice_id ?? ANALYTICKA_EVENT_NO_INVOICE_ID;
  useEffect(() => {
    Analytika.fireEvent("phone_screen_opened");
    eventAnalytics.setInvoiceId(invoiceId);
    eventAnalytics.publish("pyck_view_ck_page", null, {
      screen_name: "enter_phone",
      flow: "identity",
    });
  }, [invoiceId]);

  useEffect(() => {
    Analytika.setSessionAttr(externalParams);
  }, [externalParams]);

  useEffect(() => {
    if (isSessionStorage() && csrf) {
      sessionStorage.setItem(CSRF_SESSION_STORAGE_FIELD, csrf);
    }
  }, [csrf]);

  const onPhoneChange = (phone) => {
    if (phone.isValid) {
      setState((prevState) => ({ ...prevState, phone }));
    }
    Analytika.setSessionAttr({
      phoneNumber: sanitizePhone(phone.fullPhoneNumber)
    });
    eventAnalytics.setPhoneNumber(sanitizePhone(phone.fullPhoneNumber));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    Analytika.fireEvent("Idtn_Web_OneClick_Phone_Submit");
    eventAnalytics.publish("pyck_tap_page", null, {
      screen_name: "continue_phone",
      flow: "identity",
    });
    if (state.phone) {
      setState((prevState) => ({
        ...prevState,
        submitting: true,
        error: null
      }));
      handleLoginResponse(doLogin());
    } else {
      Analytika.fireEvent("invalid_phone_number");
      setState((prevState) => ({
        ...prevState,
        submitting: false,
        error: "Please enter your phone number."
      }));
    }
  };

  const handleErrors = (error) => {
    setState((prevState) => ({
      ...prevState,
      submitting: false,
      error: error.response?.data?.message || "no response"
    }));
  };

  const handleUnknown400Errors = (error) => {
    let errorMessage = i18n.t("NO_RESPONSE");
    if (error?.additional_information?.error_code) {
      if (error?.additional_information?.error_code === "USER_BLOCKED") {
        Analytika.fireEvent("Idtn_Web_OneClick_UserBlocked");
        errorMessage = (
          <Trans i18nKey="spring_security_error:USER_BLOCKED" t={props.t}>
            We cannot log you in at this time.
            <a href="https://help.careem.com/hc/en-us/requests/new?reason=account_issue">
              Please contact us for help.
            </a>
          </Trans>
        );
      } else {
        errorMessage = i18n.t(
          "spring_security_error:" + error?.additional_information?.error_code
        );
      }
    } else if (error?.errorCode) {
      errorMessage = i18n.t("spring_security_error:" + error?.errorCode);
    } else if (error?.error_message) {
      errorMessage = error?.error_message;
    } else if (error?.errorMessage) {
      errorMessage = error?.errorMessage;
    }
    setState((prevState) => ({
      ...prevState,
      submitting: false,
      error: errorMessage
    }));
  };

  const handleLoginResponse = (promise) => {
    promise
      .then((data) => {
        if (data.auth_challenge === "OTP") {
          Analytika.fireEvent("Idtn_Auth_InactiveUser");
          eventAnalytics.publish("pyck_response_phone_submit", null, {
            flow: "identity",
            status: "inactive"
          });
          const params = {
            view: "otp",
            clientId: state.clientId || "not_defined",
            fullMobileNumber: state.phone.fullPhoneNumber,
            isApple: true,
            showApple: true
          };
          window.location.href = `/login?${queryString.stringify(params)}`;
        } else {
          redirectToPush(data);
        }
      })
      .catch((error) => {
        Analytika.fireEvent("redirect_to_push_failed", {
          errormessage: error.response?.data?.error
        });
        eventAnalytics.publish("pyck_response_phone_submit", null, {
          flow: "identity",
          status: "error"
        });
        if (ARGUS_APM) {
          ARGUS_APM.captureError(error);
        }
        if (error.response?.status === 400) {
          handle400Errors(error);
        } else {
          handleErrors(error);
        }
      });
  };

  const doLogin = async (overrides) => {
    const params = Object.assign(
      {
        phone_number: sanitizePhone(state.phone.fullPhoneNumber),
        guard: await fetchGuardToken(),
        is_retry: false,
        external_params: externalParams,
      },
      overrides
    );
    return loginWithOneClick(params, state.clientId);
  };

  const handle400Errors = async (error) => {
    switch (error.response.data?.error) {
      case UNREGISTERED_USER: {
        const isSignupEnabled = await Galileo.fetchIsOneClickSignupEnabledFlag();
        if (isSignupEnabled) {
          Analytika.fireEvent("Idtn_Auth_UnregisteredUser");
          const params = {
            view: "otp",
            clientId: state.clientId || "not_defined",
            fullMobileNumber: state.phone.fullPhoneNumber,
            isApple: true,
            showApple: true,
          };
          window.location.href = `/login?${queryString.stringify(params)}`;
        } else {
          setState((prevState) => ({
            ...prevState,
            submitting: false,
            view: REDIRECT_TO_SIGNUP,
          }));
        }
        break;
      }
      default:
        handleUnknown400Errors(error.response.data);
        break;
    }
  };

  const redirectToPush = (response) => {
    setState((prevState) => ({
      ...prevState,
      redirectToPush: response,
      view: PUSH_NOTIFICATION,
    }));
  };

  const onConfirm = (id) => {
    proofApproveRequest(id, state.clientId)
      .then(({ token }) => {
        Analytika.fireEvent("redirecting_to_idp");
        eventAnalytics.publish("pyck_response_ck_button", null, {
          flow: "identity",
          status: "success",
          screen_name: "verification",
          button_name: "yes_it_is_me",
        });
        setState((prevState) => ({
          ...prevState,
          redirectToIdpParams: {
            fieldName: "proofToken",
            value: token,
          },
          view: REDIRECT_TO_IDP,
        }));
      })
      .catch((err) => {
        Analytika.fireEvent("proof_token_request_failed", {
          errormessage: err?.response?.data?.error ?? err.message,
        });
        eventAnalytics.publish("pyck_response_ck_button", null, {
          flow: "identity",
          status: "fail",
          screen_name: "verification",
          button_name: "yes_it_is_me",
          error_message: err?.response?.data?.error ?? err.message,
        })
        setState((prevState) => ({
          ...prevState,
          view: REJECTED_USER,
        }));
      });
  };

  const onReject = (error = "") => {
    if (error === "maxTriesReached") {
      setState((prevState) => ({
        ...prevState,
        view: MAX_TRIES_REACHED,
      }));
    } else if (error === "changePhone") {
      setState((prevState) => ({
        ...prevState,
        submitting: false,
        view: ONE_CLICK_PHONE_LOGIN_FORM_VIEW,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        view: REJECTED_USER,
      }));
    }
  };

  const { error, submitting, view } = state;
  const merchant = externalParams?.["merchant"] || "Unknown";

  switch (view) {
    case ONE_CLICK_PHONE_LOGIN_FORM_VIEW:
      return (
        <OneClickPhoneLoginForm
          error={error}
          merchant={merchant}
          submitting={submitting}
          onPhoneChange={onPhoneChange}
          onSubmit={onSubmit}
          promoValue={promoValue}
          promoType={promoType}
          promoOutcome={promoOutcome}
        />
      );
    case PUSH_NOTIFICATION:
      return (
        <PushNotificationView
          token={state.redirectToPush}
          phoneNumber={state.phone.fullPhoneNumber}
          merchant={merchant}
          clientId={state.clientId}
          doLogin={doLogin}
          onConfirm={onConfirm}
          onReject={onReject}
          invoiceId={invoiceId}
          promoValue={promoValue}
          promoType={promoType}
          promoOutcome={promoOutcome}
        />
      );
    case REDIRECT_TO_SIGNUP:
      return (
        <OneClickErrorView
          heading={i18n.t("one_click_checkout:could_not_verify_you")}
          subHeading={maskPhoneNumber(state.phone.fullPhoneNumber)}
          description={i18n.t("one_click_checkout:signup_on_careem_app")}
          showUpdateButton={true}
        />
      );
    case REDIRECT_TO_IDP:
      return (
        <RedirectToLogin
          fieldName={state.redirectToIdpParams.fieldName}
          value={state.redirectToIdpParams.value}
          rememberMe={state.rememberMe}
          csrf={
            csrf ||
            (isSessionStorage() &&
              sessionStorage.getItem(CSRF_SESSION_STORAGE_FIELD))
          }
        />
      );
    case REJECTED_USER:
      return (
        <OneClickErrorView
          heading={i18n.t("one_click_checkout:could_not_verify_you")}
          subHeading={maskPhoneNumber(state.phone.fullPhoneNumber)}
          description={i18n.t("one_click_checkout:please_update_app")}
          showUpdateButton={true}
        />
      );
    case MAX_TRIES_REACHED:
      return (
        <OneClickErrorView
          heading={i18n.t("one_click_checkout:maximum_retries_reached")}
          subHeading={maskPhoneNumber(state.phone.fullPhoneNumber)}
          description={i18n.t("one_click_checkout:please_update_app")}
          showUpdateButton={true}
        />
      );
    default:
      return <></>;
  }
}

OneClickView.propTypes = {
  location: Types.object.isRequired,
  history: Types.object.isRequired,
};

export default OneClickView;
