import React, { Component } from "react";
import load from "little-loader";
import Types from "prop-types";
import { trackPage } from "../Container";
import { loginWithApple } from "../gateway/LoginGateway";
import { initDeviceId } from "../device";
import { v4 as uuidv4 } from "uuid";
import { ARGUS_APM } from "../../apm";
import { ANALYTIKA_EVENTS } from "../../utils/constants";
import eventAnalytics from "../../analytikaV2";


// Session storage fields:
const APPLE_TOKEN_SUB = "apple.id_token";
const APPLE_FULL_NAME = "apple.fullname";

const SIGNED_JWT_REGEX = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_+/=]*$/g;

const APPLE_RESPONSE_ERROR_AUTH = "user_cancelled_authorize";
const APPLE_RESPONSE_ERROR_POPUP_CLOSED = "popup_closed_by_user";

const GA_SUCCESS_WITH_FULLNAME = "/apple_signin_started/with_fullname";
const GA_SUCCESS_WITHOUT_FULLNAME = "/apple_signin_started/without_fullname";
const GA_FAILED_AUTH = "/apple_signin_failed/user_cancelled_authorize";
const GA_FAILED_POPUP_CLOSED = "/apple_signin_failed/popup_closed_by_user";
const GA_FAILED_OTHER = "/apple_sign_in_failed/";

const NO_RESPONSE = "no response from Apple";
const UNREGISTERED_USER = "unregistered_user";

export function parseAppleToken(appleIdToken) {
  if (appleIdToken && appleIdToken.match(SIGNED_JWT_REGEX)?.[0]) {
    return JSON.parse(atob(appleIdToken.split(".")[1]));
  }
}

export function syncSessionStorageAndExtractEmail(appleIdToken, fullname) {
  let { sub, email } = parseAppleToken(appleIdToken);
  let storedSubject = sessionStorage.getItem(APPLE_TOKEN_SUB);

  if (storedSubject === sub) {
    if (fullname?.length > 0) {
      sessionStorage.setItem(APPLE_FULL_NAME, fullname);
    } else {
      fullname = sessionStorage.getItem(APPLE_FULL_NAME);
    }
  } else {
    sessionStorage.setItem(APPLE_FULL_NAME, fullname);
    sessionStorage.setItem(APPLE_TOKEN_SUB, sub);
  }
  return { appleIdToken, fullname, email };
}

class AppleButton extends Component {
  state = {
    email: null,
    fullname: null,
  };

  componentDidMount() {
    load(
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
      this.init.bind(this)
    );

    this.handleSignInSuccesfully();

    this.handleSignInFailure();
  }

  handleSignInFailure() {
    document.addEventListener("AppleIDSignInOnFailure", (data) => {
      let error = data.detail?.error ?? "";
      switch (error) {
        case APPLE_RESPONSE_ERROR_AUTH:
          trackPage(GA_FAILED_AUTH);
          break;
        case APPLE_RESPONSE_ERROR_POPUP_CLOSED:
          trackPage(GA_FAILED_POPUP_CLOSED);
          break;
        default:
          trackPage(GA_FAILED_OTHER.concat(error));
      }
      
      this.setState({ error });
    });
  }

  handleSignInSuccesfully() {
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      trackPage(
        data.detail?.user
          ? GA_SUCCESS_WITH_FULLNAME
          : GA_SUCCESS_WITHOUT_FULLNAME
      );
      this.handleAppleSignInResponse(data.detail);
    });
  }

  init(error) {
    if (!error) {
      let initParams = {
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: "email name",
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT,
        state: Math.random().toString(36).substring(3).concat("idp"),
        usePopup: true,
      };
      window.AppleID.auth.init(initParams);
    } else {
      let message = "failed to load appleId js";
      
      console.error(message);
      this.setState({ error: message });
    }
  }

  handleAppleSignInResponse(detail) {
    let parsed = this.parseAppleSignInResponse(detail);
    let { appleIdToken, email, fullname } = syncSessionStorageAndExtractEmail(
      parsed.appleIdToken,
      parsed.fullname
    );

    this.setState({ email, fullname });
    this.tryToSignin(appleIdToken);
  }

  parseAppleSignInResponse(detail) {
    let appleIdToken = detail.authorization.id_token;
    let user = detail?.user;
    let fullname = (user?.name?.firstName?.trim().concat(" ") ?? "").concat(
      user?.name?.lastName?.trim() ?? ""
    );
    return { appleIdToken, user, fullname };
  }

  async tryToSignin(appleIdToken) {
    let deviceId = await initDeviceId().catch((ex) => {
      
      ARGUS_APM.captureError(ex);
      console.log(ex);
    });
    if (!deviceId) {
      deviceId = uuidv4;
    }

    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
      {
        request_type: "apple_signin_signup",
      }
    );
    return this.loginAppleRequest(deviceId, appleIdToken)
      .then((data) => {
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "apple_signin",
            request_status: "success",
          }
        );
        this.props.onChange({ proofToken: data.token }); // login IdP
      })
      .catch((error) => {
        
        ARGUS_APM.captureError(error);
        if (
          error.response?.status === 400 &&
          error.response.data?.error === UNREGISTERED_USER
        ) {
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
            {
              request_type: "apple_signup",
              request_status: "success",
            }
          );
          this.props.onChange({
            appleIdToken,
            email: this.state.email,
            fullname: this.state.fullname,
          }); // signup
        } else {
          const _error = error.response?.data?.message || NO_RESPONSE;
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
            {
              request_type: "apple_signin_signup",
              request_status: "fail",
              error_code: "",
              error_desc: _error,
            }
          );
          this.props.onChange({
            error: _error,
          });
        }
      });
  }

  loginAppleRequest(deviceId, appleIdToken) {
    return loginWithApple(
      {
        device_id: deviceId,
        access_token: appleIdToken,
      },
      this.state.clientId
    );
  }

  render() {
    return (
      <div
        onClick={() => {
          eventAnalytics.setFlowName("apple");
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
            ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
            { button_name: "signin_with_apple" }
          );
        }}
        id="appleid-signin"
        className="signin-button"
        data-color="black"
        data-border="true"
        data-type="sign in"
      />
    );
  }
}

AppleButton.propTypes = {
  onChange: Types.func,
};

export default AppleButton;
