import React from "react";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";
import ErrorBlock from "./ErrorBlock";
import AlertShow from "./AlertInfo";
import AlertAcknowledged from "./AlertAcknowledged";

export default class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      acknowledged: false,
      alert: null,
      error: null,
    };
  }

  componentDidMount() {
    document.title = "Alerts | Careem";
    this.loadAlert();
  }

  loadAlert() {
    axios
      .get(
        `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/alerts/${this.props.match.params.alert}`
      )
      .then((response) => {
        this.setState({ alert: response.data, loading: false });
      })
      .catch((failure) => {
        console.error(failure);
        let error;

        if (failure.response) {
          error = "HTTP-" + failure.response.status;
        } else {
          error = failure.message || "Unknown Error"; //Ensure error is always set
        }

        this.setState({ error: error, loading: false });
      });
  }

  acknowledgeAlert() {
    this.setState({ loading: true });

    axios
      .put(
        `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/alerts/${this.props.match.params.alert}/acknowledgement`
      )
      .then((_) => {
        this.setState({ acknowledged: true, loading: false });
      })
      .catch((failure) => {
        console.error(failure);
        let error;

        if (failure.response) {
          error = "HTTP-" + failure.response.status;
        } else {
          error = failure.message || "Unknown Error"; //Ensure error is always set
        }

        this.setState({ error: error, loading: false });
      });
  }

  render() {
    const { loading, alert, acknowledged, error } = this.state;

    if (loading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return <ErrorBlock code={error} />;
    }

    if (acknowledged) {
      return <AlertAcknowledged />;
    }

    if (alert) {
      return (
        <AlertShow
          acknowledgeAlertCallback={this.acknowledgeAlert.bind(this)}
          alert={alert}
        />
      );
    }

    // Fall through case
    return <ErrorBlock code={"Unknown Error"} />;
  }
}
