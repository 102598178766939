import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import LanguageSelector from "../LanguageSelector";
import { Trans, withTranslation } from "react-i18next";

class ErrorBlock extends React.Component {
  componentDidMount() {
    document.title = "Alerts | Careem";
  }

  render() {
    const { code, t } = this.props;

    return (
      <div>
        <LanguageSelector />
        <h1>
          <CancelIcon className={"largeIcon failureColor"} />
        </h1>
        <h1>{t("alert:alert_error_title")}</h1>
        <hr />
        <p style={{ textAlign: "center" }}>
          <Trans i18nKey="alert:alert_error_help" t={t}>
            We couldn't process your request. If the problem persists, please
            contact our <a href="https://help.careem.com">Care Team</a>.
          </Trans>
        </p>
        <h5>Support Information: {code}</h5>
      </div>
    );
  }
}

export default withTranslation()(ErrorBlock);
