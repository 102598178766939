import React from "react";
import Types from "prop-types";
import LanguageSelector from "../LanguageSelector";
import { ReCaptcha, fetchReCaptchaToken } from "../Guard";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Galileo from "../../galileo";
import eventAnalytics from "../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../utils/constants";
import { bugsnagInstance } from "../../bugsnag";

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName("new_password");
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: "new_password",
      }
    );
    this.state = {
      password: "",
      error: null,
      showPassword: false,
      isPasswordChanged: false,
      listOfWeakPasswords: [],
      isInvalidPassword: null,
    };
    this.passwordRef = null;
    Galileo.fetchWeakPasswords();
  }

  onSubmit(event) {
    event.preventDefault();
    const token = this.props.match.params.token;

    const { password } = this.state;
    const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/recovery/password`;

    this.setState({ submitting: true, error: null });

    let recaptchaPromise = fetchReCaptchaToken("forgot_password_email");

    recaptchaPromise
      .then((recaptchaToken) =>
        axios.put(endpoint, { token, password, recaptchaToken })
      )
      .then((res) => {
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "password_reset",
            request_status: "success",
          }
        );
        return res.data;
      })
      .then((res) => this.setState({ isPasswordChanged: true }))
      .catch((err) => {
        bugsnagInstance.notify(err);
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "password_reset",
            request_status: "fail",
            error_code_string: err.response?.status?.toString(),
            error_description: err,
          }
        );
        this.setState({ submitting: false, error: err.response?.data });
      });
  }

  onChange(event) {
    const value = event.target.value;
    if (value.length <= 1) {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_version
      );
    }
    var errorMessage = null;
    var isInvalidPassword = false;
    if (value.length > 0 && value.length < 6) {
      isInvalidPassword = true;
      errorMessage = `Please lengthen your password to 6 characters or more (you are currently using ${value.length} characters)`;
    } else if (
      value &&
      this.state.listOfWeakPasswords?.some(
        (_password) => _password.toLowerCase() === value.toLowerCase()
      )
    ) {
      isInvalidPassword = true;
      errorMessage =
        "Your password is easily guessed. Please try a stronger password.";
    }
    bugsnagInstance.notify(errorMessage);
    this.setState({
      error: errorMessage,
      password: event.target.value,
      isInvalidPassword: isInvalidPassword,
    });
  }

  onToogleChange({ target }) {
    this.setState({ showPassword: target.checked });
  }

  componentDidMount() {
    Galileo.fetchWeakPasswords().then((_list) => {
      if (_list) {
        this.setState({ listOfWeakPasswords: _list.split(",") });
      }
    });
    document.title = "Reset password | Careem";
  }

  render() {
    const { t } = this.props;
    const { error, submitting, isPasswordChanged } = this.state;

    if (isPasswordChanged) {
      return <Redirect to="/password_success" />;
    }

    return (
      <form id="formNewPassword" onSubmit={this.onSubmit.bind(this)}>
        <LanguageSelector />
        <h1>{t("reset_password:enter_new_password")}</h1>
        {error && <div className="invalid show">{error}</div>}

        <div className="material-form-field ">
          <input
            className="password-field"
            type={this.state.showPassword ? "input" : "password"}
            autoComplete="off"
            spellCheck="false"
            id="newPassword"
            name="newPassword"
            onChange={this.onChange.bind(this)}
            required
            ref={(ref) => (this.passwordRef = ref)}
          />
          <label className="material-form-field-label" htmlFor="newPassword">
            {t("reset_password:new_password")}
          </label>
        </div>

        <label className="reset-password-label" htmlFor="show-password-toggle">
          <input
            type="checkbox"
            id="show-password-toggle"
            onChange={this.onToogleChange.bind(this)}
          />
          <span>&nbsp;{t("reset_password:show_password")}</span>
        </label>

        <div className="material-form-field password-hint">
          {t("reset_password:strong_password_info")}
        </div>

        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="reset-pass-submit"
          disabled={submitting || this.state.isInvalidPassword}
          onClick={() =>
            eventAnalytics.publish(
              ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
              ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
              {
                button_name: "continue",
              }
            )
          }
        />

        <ReCaptcha />
      </form>
    );
  }
}

NewPassword.propTypes = {
  t: Types.func.isRequired,
};

export default withTranslation()(NewPassword);
