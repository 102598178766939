import React from "react";
import { withTranslation } from "react-i18next";

class SessionUnknown extends React.Component {
  componentDidMount() {
    document.title = "Invalid session | Careem";
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_IDENTITY_REDIRECT;
    }, 2000);
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        {t("invalid_session:session_invalid")} <br />
        {t("invalid_session:you_will_be_redirected_to")}{" "}
        <a href={process.env.REACT_APP_IDENTITY_REDIRECT}>
          {t("invalid_session:login_link_lext")}
        </a>
        .{t("invalid_session:retry")}
      </div>
    );
  }
}

export default withTranslation()(SessionUnknown);
