import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import Types from "prop-types";
import eventAnalytics from "../analytikaV2";
import { ANALYTIKA_EVENTS } from "../utils/constants";

const languages = [
  {
    code: "en",
    shortName: "EN",
    name: "English",
    direction: "ltr",
  },
  {
    code: "ar",
    shortName: "AR",
    name: "العربية",
    direction: "rtl",
  },
  {
    code: "ur",
    shortName: "UR",
    name: "اردو",
    direction: "rtl",
  },
  {
    code: "fr",
    shortName: "FR",
    name: "Français",
    direction: "ltr",
  },
  {
    code: "ckb",
    shortName: "CKB",
    name: "سۆرانی",
    direction: "rtl",
  },
];

export class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    let selectedLanguage =
      languages.find((lang) => lang.code === this.props.i18n.language) ||
      languages[0];
    this.state = { isSelectorVisible: false, selectedLanguage };
    document.dir = selectedLanguage.direction;
    document.documentElement.lang = selectedLanguage.code;
  }

  _onToggleLanguageList() {
    this.setState((prevState) => ({
      isSelectorVisible: !prevState.isSelectorVisible,
    }));
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_OPTION.event_version,
      {
        option_name: "language_selection",
      }
    );
  }

  _onChangeLanguage(language) {
    this.props.i18n.changeLanguage(language.code);
    this.setState({ selectedLanguage: language, isSelectorVisible: false });
    document.dir = language.direction;
    document.documentElement.lang = language.code;

    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SELECT_LANGUAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_SELECT_LANGUAGE.event_version,
      {
        language_code: language.code,
      }
    );
  }

  render() {
    const { isEnhancedFormEnabled } = this.props;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }

  renderEnhanced() {
    let { selectedLanguage, isSelectorVisible } = this.state;
    return (
      <div
        id="lang-menu"
        className={`${
          selectedLanguage.direction === "ltr"
            ? "left-to-right"
            : "right-to-left"
        }-lang`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            lineHeight: 3,
          }}
          onClick={this._onToggleLanguageList.bind(this)}
        >
          <LanguageOutlinedIcon
            fontSize="large"
            className="express-icon-green"
          />
          <span className="selected-lang express-selected-lang">
            {selectedLanguage.name}
          </span>
          <span className="fa fa-angle-down fa-2x arrow-fix express-icon-green" />
        </div>
        <div
          className={`lang-list-wrapper ${
            isSelectorVisible ? "open" : "closed"
          }-lang-list-wrapper`}
        >
          <ul>
            {languages.map((lang, i) => (
              <li
                key={i}
                className={
                  selectedLanguage.code === lang.code ? "hidden" : undefined
                }
                onClick={this._onChangeLanguage.bind(this, lang)}
              >
                {lang.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  renderClassic() {
    let { selectedLanguage, isSelectorVisible } = this.state;
    return (
      <div>
        <div
          id="lang-menu"
          className={`${
            selectedLanguage.direction === "ltr"
              ? "left-to-right"
              : "right-to-left"
          }-lang`}
        >
          <div onClick={this._onToggleLanguageList.bind(this)}>
            <span className="selected-lang">{selectedLanguage.name}</span>
            <span className="fa fa-angle-down fa-2x arrow-fix black-link" />
          </div>
          <div
            className={`lang-list-wrapper ${
              isSelectorVisible ? "open" : "closed"
            }-lang-list-wrapper`}
          >
            <ul>
              {languages.map((lang, i) => (
                <li
                  key={i}
                  className={
                    selectedLanguage.code === lang.code ? "hidden" : undefined
                  }
                  onClick={this._onChangeLanguage.bind(this, lang)}
                >
                  {lang.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="clearFloats"></div>
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
  isEnhancedFormEnabled: Types.bool,
};

export default withTranslation()(LanguageSelector);
