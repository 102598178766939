import React from "react";
import Types from "prop-types";
import LanguageSelector from "../../LanguageSelector";
import TryAnotherWayImg from "../../../images/try-another-way.svg";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Analytika from "../../../analytika";
import eventAnalytics from "../../../analytikaV2";
import { ANALYTIKA_EVENTS } from "../../../utils/constants";
import VerificationInput from "react-verification-input";

export class VerifyPinLoginForm extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.VERIFY_PIN_FORM_VIEW);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.VERIFY_PIN_FORM_VIEW,
      }
    );
    Analytika.fireEvent("Idtn_Web_PinLogin_View", {
      phonenumber: props.fullPhoneNumber,
    });
    this.onPinChange = props.onPinChange;
    this.onTryAnotherClickHandler = props.onTryAnotherClickHandler;
    this.isTryAnotherWayVisible = props.isTryAnotherWayVisible;
    this.onSubmit = props.onSubmit;
    this.createAccount = props.createAccount;
  }

  render() {
    return this.renderClassic();
  }

  renderClassic() {
    const { t, error, submitting } = this.props;

    return (
      <form data-request-type="Pin_submit" onSubmit={this.onSubmit}>
        <Helmet>
          <title>Verify Pin | Careem</title>
        </Helmet>

        <div className="help-lang-container">
          <LanguageSelector />
        </div>
        <h1>{t("login:verify_pin_title")}</h1>

        {error && <div className="invalid show">{error}</div>}

        <div className="verify-card-hint">{t("login:lbl_verify_pin")}</div>

        <VerificationInput
          length={4}
          placeholder=""
          passwordMode={true}
          classNames={{
            container: "pin-container",
            character: "pin-character",
            characterSelected: "pin-selected",
          }}
          onChange={this.onPinChange}
        />

        {this.isTryAnotherWayVisible && (
          <div
            className="btn-try-another-way"
            name="btn-try-another-way"
            onClick={this.onTryAnotherClickHandler}
          >
            <img src={TryAnotherWayImg} alt="TryAnotherWayImg" width="20px" />
            {t("login:try-another-way")}
          </div>
        )}

        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="btn-verify-pin"
          disabled={submitting}
        />
        <input
          className="btn-careem-tertiary"
          type="button"
          value={t("login:create_account")}
          name="btn-create-account"
          onClick={this.createAccount}
        />

        <ReCaptcha />
      </form>
    );
  }
}

VerifyPinLoginForm.propTypes = {
  t: Types.func.isRequired,
  onPinChange: Types.func.isRequired,
  onTryAnotherClickHandler: Types.func.isRequired,
  isTryAnotherWayVisible: Types.bool.isRequired,
  onSubmit: Types.func.isRequired,
  createAccount: Types.func.isRequired,
  fullPhoneNumber: Types.string,
  error: Types.string,
  submitting: Types.bool,
};
export default withTranslation()(VerifyPinLoginForm);
