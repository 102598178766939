import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

import { useTimer } from "../../../otp/Timer";
import { DEFAULT_RETRY_TIMEOUT } from "../constants";
import { ReactComponent as CircularTickIcon } from "../../../../images/circular-tick.svg";
import styles from "../styles";
import { withTranslation, Trans } from "react-i18next";

const RemainingTime = (props) => {
  const { classes, onRetry, t, i18n } = props;
  const [showTimer, setShowTimer] = useState(false);
  const [retrySeconds, setRetrySeconds] = useTimer(DEFAULT_RETRY_TIMEOUT + 2);

  useEffect(() => {
    setTimeout(() => {
      setShowTimer(true);
    }, 2000);
  }, []);

  const clickAndResetTimer = (event) => {
    onRetry(event);
    setRetrySeconds(DEFAULT_RETRY_TIMEOUT);
  };

  return (
    <Box
      textAlign="center"
      position="relative"
      height="24px"
      marginTop="12px"
      overflow="hidden"
    >
      <Fade in={!showTimer}>
        <Box className={classes.notificationBox}>
          <span className={classes.tickIcon}>
            <CircularTickIcon />
          </span>
          <Typography component="p" className={classes.notificationText}>
            {t("one_click_checkout:notification_sent")}
          </Typography>
        </Box>
      </Fade>
      <Slide
        direction="left"
        in={showTimer && retrySeconds > 0}
        unmountOnExit
        mountOnEnter
      >
        <Typography
          dir={i18n.dir()}
          component="p"
          className={classes.notificationText}
        >
          <Trans t={t} i18nKey="one_click_checkout:resend_push_sms_in_seconds">
            Resend Push/SMS in
            <span className={classes.grey500}>
              {{ seconds: retrySeconds }} sec
            </span>
          </Trans>
        </Typography>
      </Slide>
      <Slide
        direction="right"
        in={retrySeconds <= 0}
        unmountOnExit
        mountOnEnter
      >
        <Typography component="p" className={classes.notificationText}>
          {t("common:or")}{" "}
          <Typography
            component="button"
            className={clsx(classes.textButton, classes.textSmall)}
            onClick={clickAndResetTimer}
          >
            {t("one_click_checkout:resend_notification")}
          </Typography>
        </Typography>
      </Slide>
    </Box>
  );
};

RemainingTime.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(RemainingTime));
