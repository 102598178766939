export function initDeviceId() {
  const DeviceSdk = window.DeviceSdk;
  if (DeviceSdk === undefined) {
    return Promise.reject();
  }
  const deviceSdk = new DeviceSdk({
    token: `${process.env.REACT_APP_DEVICE_MANAGEMENT_TOKEEN}`,
    baseUrl: `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}`,
  });
  return deviceSdk.getDeviceId();
}

export function initProfiling() {
  const DeviceSdk = window.DeviceSdk;
  if (DeviceSdk === undefined) {
    return Promise.reject();
  }
  const deviceSdk = new DeviceSdk({
    token: `${process.env.REACT_APP_DEVICE_MANAGEMENT_TOKEEN}`,
    baseUrl: `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}`,
  });
  return deviceSdk.collectProfiling();
}
