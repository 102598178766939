import React from "react";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorBlock from "./ErrorBlock";
import { bugsnagInstance } from "../../bugsnag";

class ConfirmProfileUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tokenInvalid: false,
      error: null,
    };

    this.uri = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/partner/users/profile/confirm/${this.props.match.params.token}`;
  }

  componentDidMount() {
    document.title = "Profile | Careem";
    let { tokenInvalid, error } = this.state;
    axios
      .post(this.uri, {})
      .catch((axiosError) => {
        bugsnagInstance.notify(axiosError);
        if (axiosError.response?.data?.errorCode) {
          error = axiosError.response?.data?.errorCode;

          if (axiosError.response.data.errorCode === "US-0002") {
            tokenInvalid = true;
          }
        } else if (axiosError.response) {
          error = "HTTP-" + axiosError.response.status;
        } else {
          error = axiosError.message || "Unknown Error"; //Ensure error is always set
        }
      })
      .finally(() =>
        this.setState({
          loading: false,
          error: error,
          tokenInvalid: tokenInvalid,
        })
      );
  }

  render() {
    const { loading, tokenInvalid, error } = this.state;

    if (loading) {
      return <LoadingSpinner />;
    }

    if (tokenInvalid) {
      return (
        <ErrorBlock
          code={error}
          title="The update link is invalid or has expired."
          message={
            "Profile update confirmation must be done within 3 days of the original request."
          }
        />
      );
    }

    if (error) {
      return (
        <ErrorBlock
          code={error}
          title={"An error occurred while updating your profile."}
          message={"We're sorry!"}
        />
      );
    }

    return (
      <div>
        <h1>
          <CheckCircleOutlineIcon className={"largeIcon successColor"} />
        </h1>
        <h1>Your profile has been successfully updated.</h1>
        <h3>
          Click <a href="https://www.careem.com">here</a> to return to Careem
        </h3>
      </div>
    );
  }
}

export default ConfirmProfileUpdate;
