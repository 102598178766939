/**
 * Detects the Android and iOS device.
 *
 * @returns {isAndroid: boolean, isIOS: boolean}
 */
export default function getMobileOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return {
    isAndroid: /android/i.test(userAgent),
    isIOS: /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream,
  };
}

export const { isAndroid, isIOS } = getMobileOS();
