// eslint-disable-next-line
import React from 'react'
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from '@bugsnag/browser-performance'

const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;
const BUGSNAG_ENV_STAGE = process.env.REACT_APP_BUGSNAG_ENV_STAGE;

export const bugsnagInstance = Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: BUGSNAG_ENV_STAGE,
  maxBreadcrumbs: 100,
  plugins: [new BugsnagPluginReact()],
});

export const bugsnagPerformanceInstance = BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY })