import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { maskPhoneNumber } from "../../../common/PhoneNumber";
import styles from "../styles";
import { withTranslation } from "react-i18next";

function ChangePhone(props) {
  const { classes, phoneNumber, onChangePhone, t } = props;

  return (
    <Box
      component="span"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gridColumnGap={6}
    >
      <Typography component="p" className={classes.formSubtitle}>
        {maskPhoneNumber(phoneNumber)}
      </Typography>
      <Typography
        component="button"
        className={clsx(classes.formSubtitle, classes.textButton)}
        onClick={onChangePhone}
      >
        {t("one_click_checkout:change")}
      </Typography>
    </Box>
  );
}

ChangePhone.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  onChangePhone: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(ChangePhone));
