import React from "react";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";
import {
  CorporateContainer,
  CorporateLogo,
  CorporateFooter,
} from "./CorporateTemplate";

export default class CorporateEmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidToken: false,
      loading: true,
    };
  }

  componentDidMount() {
    document.title = "Corporate | Careem";
    const token = this.props.match.params.token;
    this.verifyUserEmail(token);
  }

  verifyUserEmail(token) {
    const endpoint = `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/v1/verify/email/${token}`;
    axios
      .post(endpoint, {})
      .then((res) => res.status)
      .then((status) => {
        this.setState({ isValidToken: true, loading: false });
      })
      .catch(() => {
        this.setState({ isValidToken: false, loading: false });
      });
  }
  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    } else if (this.state.isValidToken) {
      return (
        <CorporateContainer>
          <CorporateLogo />
          <div style={{ padding: "30px 20px", backgroundColor: "#FFFFFF" }}>
            <p style={{ textAlign: "center" }}>
              <strong>Your email has been verified successfully !</strong>
            </p>
            <p style={{ textAlign: "center" }}>Regards,</p>
            <p style={{ textAlign: "center" }}>Team Careem</p>
            <CorporateFooter />
          </div>
        </CorporateContainer>
      );
    } else {
      return (
        <CorporateContainer>
          <CorporateLogo />
          <div style={{ padding: "30px 20px", backgroundColor: "#FFFFFF" }}>
            <p style={{ textAlign: "center" }}>
              <strong>
                We couldn't verify your email. Please contact careem customer
                service.
              </strong>
            </p>
            <CorporateFooter />
          </div>
        </CorporateContainer>
      );
    }
  }
}
