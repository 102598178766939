const styles = () => ({
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "12px",
  },
  checkboxLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#355273",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#2D2E2E",
  },
  formSubtitle: {
    fontSize: "16px",
    color: "#355273",
    "& > span": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  title: {
    marginBottom: "1.5rem",
  },
  sizeControl: {
    display:'grid',
    alignContent: "center",
    height: "537px",
  },
  container:{
    display:'flex',
    alignItems: 'center',
    justifyContent:'center',
    flexWrap: 'wrap',
  },
  promoContainer:{
    display:'flex',
    margin:'0 4px',
    height: '40px',
    borderRadius:'20px',
    gap: '4px',
    padding: '8px 0',
  },
  promoText: {
    display:'flex-inline',
    whiteSpace: 'pre-wrap',
    fontSize:'16px',
    unicodeBidi: "embed",
  },
  offerIcon:{
    height:'10px',
    margin:'2px 0px',

  },
  amount:{
    fontWeight:700,
    color:'#00493E',
  }
});

export default styles;
