// Views
export const ONE_CLICK_PHONE_LOGIN_FORM_VIEW = "one_click_phone_form";
export const REDIRECT_TO_IDP = "redirect_to_idp";
export const REDIRECT_TO_SIGNUP = "redirect_to_signup";
export const PUSH_NOTIFICATION = "push_notification";

// Errors
export const UNREGISTERED_USER = "invalid_user";
export const REJECTED_USER = "rejected_user";
export const MAX_TRIES_REACHED = "max_tries_reached";

// Push notification
export const DEFAULT_RETRY_TIMEOUT = 90;
export const MAX_RETRIES = 5;
