import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Types from "prop-types";
import { withRouter } from "react-router-dom";

export const useTimer = (timeInSecs) => {
  const [seconds, setSeconds] = useState(timeInSecs);

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return [seconds, setSeconds];
};

const Timer = (props) => {
  const {timeInSecs,onFinish,t} = props
  let [seconds] = useTimer(timeInSecs);

  useEffect(() => {
    if (seconds === 0 && onFinish) {
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <span className="resend-timer-counter">
      &nbsp; {seconds} {t("common:seconds")}
    </span>
  );
};

Timer.propTypes = {
  timeInSecs: Types.number.isRequired,
  onFinish: Types.func,
  t: Types.func,
};

export default withRouter(withTranslation()(Timer));
