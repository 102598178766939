import React from "react";
import Types from "prop-types";
import LanguageSelector from "../../LanguageSelector";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";

export class EmailLoginForm extends React.Component {
  render() {
    const { t, error, submitting } = this.props;
    return (
      <form onSubmit={this.props.onSubmit}>
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        <LanguageSelector />
        <h1>{t("login:title")}</h1>


        {error && <div className="invalid show">{error}</div>}

        <div className="material-form-field">
          <input
            autoFocus
            type="email"
            autoComplete="off"
            id="email"
            name="email"
            onChange={this.props.onEmailChange}
            required={true}
          />
          <label className="material-form-field-label" htmlFor="email">
            {t("signup:email")}
          </label>
        </div>

        <input
          className="btn-careem"
          type="submit"
          value={submitting ? t("common:please_wait") : t("common:continue")}
          name="verify-recover-btn"
          disabled={submitting}
        />

        <ReCaptcha />
      </form>
    );
  }
}

EmailLoginForm.propTypes = {
  t: Types.func.isRequired,
  state: Types.object.isRequired,
  error: Types.string,
  onEmailChange: Types.func.isRequired,
  onSubmit: Types.func.isRequired,
  submitting: Types.bool.isRequired,
};
export default withTranslation()(EmailLoginForm);
